import React from 'react';
// import { FaUserLock } from 'react-icons/fa';
import { MdMore } from 'react-icons/md';

// import { Link } from '@components/Link';
import { Menu, } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
// import { WEB_ADMIN_ROUTE } from '@util/constants';

export const GuestAppBar = () => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = () => (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      {/* <MenuItem divider autoFocus>
        {renderLoginButton('text')}
      </MenuItem> */}
    </Menu>
  );

  // const renderLoginButton = (variant: any) => (
  //   <Button
  //     variant={variant}
  //     size="large"
  //     aria-label="login"
  //     color="inherit"
  //     component={Link}
  //     href={WEB_ADMIN_ROUTE.LOGIN}
  //     startIcon={<FaUserLock />}>
  //     Login
  //   </Button>
  // );
  return (
    <>
      {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        {renderLoginButton('outlined')}
      </Box> */}
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit">
          <MdMore />
        </IconButton>
      </Box>
      {renderMobileMenu()}
    </>
  );
};
