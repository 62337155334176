import React, { useReducer } from 'react';
import {
  MdExpandLess,
  MdExpandMore,
  MdOutlineAddCircle,
  MdOutlineFormatListNumbered,
} from 'react-icons/md';

import { Link } from '@components/Link';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Box } from '@mui/system';
import { ROUTE_CRUD } from '@util/constants';

interface Props {
  icon: any;
  title: string;
  defaultOpenList?: boolean;
  mainLink: string;
}
export const DrawerItems = (props: Props) => {
  const { icon, title, defaultOpenList = false, mainLink } = props;
  const [openList, toggleList] = useReducer((value) => !value, defaultOpenList);

  const renderList = (icon: any, text: string, link: string) => (
    <List
      component={Link}
      disablePadding
      key={text}
      href={link}
      color="inherit"
      underline="none">
      <ListItemButton sx={{ pl: 4 }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} />
        </Box>
      </ListItemButton>
    </List>
  );
  return (
    <Box>
      <ListItemButton onClick={toggleList}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
        {openList ? <MdExpandLess /> : <MdExpandMore />}
      </ListItemButton>
      <Collapse in={openList} timeout="auto" unmountOnExit>
        {data.map((item) =>
          renderList(item.icon, item.label, mainLink + item.link),
        )}
      </Collapse>
    </Box>
  );
};

const data = [
  {
    icon: <MdOutlineFormatListNumbered size={22} />,
    label: 'List',
    link: ROUTE_CRUD.LIST,
  },
  {
    icon: <MdOutlineAddCircle size={22} />,
    label: 'Create',
    link: ROUTE_CRUD.CREATE,
  },
];
