export const WEB_ADMIN_ROUTE = {
  HOME: '/admin',

  REGISTER: '/admin/register',
  LOGIN: '/admin/login',
  LOGOUT: '/admin/logout',

  ACCOUNT: '/admin/account',
  SETTINGS: '/admin/settings',

  FORGOT_PASSWORD: 'Forgot Password',
  CHANGE_PASSWORD: '/admin/change-password',
  CONFIRM_EMAIL: '/admin/confirm-email',

  VIDEO_CHAT_JOIN: '/video-chat/join',

  FINANCE: '/finance',

  CITY: '/admin/city',
  ADD_CITY: '/admin/city/add',
  UPDATE_CITY: '/admin/city/update',

  JOB: '/admin/job',
  CREATE_JOB: '/admin/job/create',
  JOB_UPDATE: '/admin/job/update',
  JOB_VIEW: '/admin/job/view',

  REPORT: '/admin/report',
  REPORT_VIEW: '/admin/report/view',
  REPORT_UPDATE: '/admin/report/update',

  CLIENT: '/admin/client',
  CREATE_CLIENT: '/admin/client/create',
  UPDATE_CLIENT: '/admin/client/update',

  SUPPLIER: '/admin/supplier',
  CREATE_SUPPLIER: '/admin/supplier/create',
  UPDATE_SUPPLIER: '/admin/supplier/update',

  ADMIN: '/admin/admin',
  CREATE_ADMIN: '/admin/admin/create',
  UPDATE_ADMIN: '/admin/admin/update',

  CHAT: '/admin/chat',
  CHAT_VIEW: '/admin/chat/view',

  SERVICE: '/admin/service',
  LIST_SERVICE: '/admin/service/list',
  CREATE_SERVICE: '/admin/service/create',
  UPDATE_SERVICE: '/admin/service/update',
};

export const WEB_CLIENT_ROUTE = {
  HOME: '/',
  BOOKING: '/booking',
  CONTACT: 'Contact',
  FAQ: '/faq',
  PRIVACY_POLICY: '/privacy-policy',
  COOKIE_POLICY: '/cookie-policy',
  LOGIN: '/client/login',
  REGISTER: '/client/register',
  LOGOUT: '/client/logout',
  CHANGE_PASSWORD: '/client/changePassword',
  CONFIRM_EMAIL: '/client/profile/confirm-email',
  FORGOT_PASSWORD: '/client/profile/forgot-password',
  RESET_PASSWORD: '/client/profile/reset-password',

  ACCOUNT: '/client/account',
  PROFILE_UPDATE: '/client/profile/update',

  CREATE_JOB: '/client/job/create',
};

export const ROUTE_CRUD = {
  CREATE: '/create',
  READ: '/view',
  UPDATE: '/update',
  DELETE: '/delete',
  LIST: '/list',
};

export const STALE_TIME = {
  SERVICE_TYPE: 10 * 60 * 1000,
};

export const SERVICE_TYPE_OPTION = {
  FIXED: 'Fixed',
  HOURLY: 'Hourly',
};
