import { DrawerHeader } from '@admin/header/drawer/DrawerComponent';
import { Box } from '@mui/system';

import { HeaderConnector } from '../header/HeaderConnector';
import { Footer } from './Footer';

export const Layout = ({ children }: any) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <HeaderConnector />
      <Box component="main" sx={{ flexGrow: 1, p: 3, minHeight: '100vh' }}>
        <DrawerHeader />
        {children}
      </Box>
      <footer>
        <Footer />
      </footer>
    </Box>
  );
};

export const getLayout = (page: any) => <Layout>{page}</Layout>;
