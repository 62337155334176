export const WEB_CLIENT_ROUTE = {
  CHANGE_PASSWORD: '/client/changePassword',
  CONFIRM_EMAIL: '/client/profile/confirm-email',
  FORGOT_PASSWORD: '/client/profile/forgot-password',
  RESET_PASSWORD: '/client/profile/reset-password',
  VIEW_JOB: '/client/job/view',
};

export const WEB_SUPPLIER_ROUTE = {
  CHANGE_PASSWORD: '/supplier/changePassword',
  CONFIRM_EMAIL: '/supplier/profile/confirm-email',
  FORGOT_PASSWORD: '/supplier/profile/forgot-password',
  RESET_PASSWORD: '/supplier/profile/reset-password',
  VIEW_JOB: '/supplier/job/view',
  CHAT_DETAILS: '/supplier/chat/view',
};

export const TEXT = {
  APP_LOGO_NAME: 'Qeeper',
  AUTH_HOME_TITLE: 'Fixing Time!',
  LOGGED_HOME_TITLE: 'Fixing broken things',
};

export interface IRules {
  rule: RegExp;
  message: string;
}

export interface IOptionType {
  id: number;
  name: string;
}

export const LABELS = {
  LOGIN_LINK: 'Existing account? Log in',
  REGISTER_LINK: 'Create a New Account',
  FORGOT_PASSWORD: 'Forgot Password',
};

export const JWT_COOKIE = 'token';

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSZ';
export const COOKIE_PREFIX = 'qid';

export const ICON_TYPE = {
  MATERIAL: 'MATERIAL',
  MATERIAL_COMMUNITY: 'MATERIAL_COMMUNITY',
};

export interface AutoCompleteDataType {
  id: string;
  name: string;
}

export const NEW = 'new';
export const QUERY = '[query]';

export const GOOGLE_BUCKET = 'https://storage.googleapis.com/';

export const BUCKET_NAMES = {
  qeeperFiles: 'qeeper-files/',
  JOB: 'qeeper-job-files/',
};

export const BUCKET_LIST: {
  [key: string]: { qeeperFiles: string; JOB: string };
} = {
  development: {
    qeeperFiles: 'qeeper-dev/',
    JOB: 'qeeper-dev/',
  },
  production: {
    qeeperFiles: 'qeeper-files/',
    JOB: 'qeeper-job-files/',
  },
};

export const ROLE_LIST = {
  SUPERADMIN: {
    id: 1,
    name: 'SUPERADMIN',
    description: 'Super administrator - monitor financial aspects only',
  },
  ADMIN: {
    id: 2,
    name: 'ADMIN',
    description: 'Administrator - monitor daily activities',
  },
  OPS: {
    id: 3,
    name: 'OPS',
    description: 'OPS - monitor daily activities',
  },
  CLIENT: {
    id: 4,
    name: 'CLIENT',
    description: 'Client - who requests for services',
  },
  SUPPLIER: {
    id: 5,
    name: 'SUPPLIER',
    description: 'Supplier - who provides the services',
  },
};

export const ADMIN_ROLE_LIST = [
  ROLE_LIST.SUPERADMIN.id,
  ROLE_LIST.ADMIN.id,
  ROLE_LIST.OPS.id,
];

export const PERMISSION_CATEGORY = {
  JOB: 'JOB',
  AUTH: 'AUTH',
  CITY: 'CITY',
  CLIENT: 'CLIENT',
  SUPPLIER: 'SUPPLIER',
  OPS: 'OPS',
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN',
};

export const PERMISSION_TYPE = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

export const ADMIN_JOB_STATUS = {
  NEW_JOB: {
    id: 100,
    title: 'New Job',
    description: 'New Job',
    roleId: ROLE_LIST.OPS.id,
  },
  WAITING_CLIENT_CONFIRM: {
    id: 200,
    title: 'Waiting on Client Confirmation ',
    description: 'Waiting on Client Confirmation ',
    roleId: ROLE_LIST.OPS.id,
  },
  AWAITING_ACCESS: {
    id: 300,
    title: 'Awaiting Access',
    description: 'Awaiting Access',
    roleId: ROLE_LIST.OPS.id,
  },
  AWAITING_PARTS: {
    id: 400,
    title: 'Awaiting Parts',
    description: 'Awaiting Parts',
    roleId: ROLE_LIST.OPS.id,
  },
  AWAITING_QUOTATION: {
    id: 500,
    title: 'Awaiting Quotation',
    description: 'Awaiting Quotation',
    roleId: ROLE_LIST.OPS.id,
  },
  SCHEDULED: {
    id: 600,
    title: 'Scheduled',
    description: 'scheduled',
    roleId: ROLE_LIST.OPS.id,
  },
  ATTENDED: {
    id: 700,
    title: 'Attended',
    description: 'Attended',
    roleId: ROLE_LIST.OPS.id,
  },
  ISSUE: {
    id: 800,
    title: 'Issue',
    description: 'Issue',
    roleId: ROLE_LIST.OPS.id,
  },
  CANCELLED: {
    id: 900,
    title: 'Cancelled',
    description: 'Cancelled',
    roleId: ROLE_LIST.OPS.id,
  },
  CHARGED_CANCELLATION: {
    id: 1000,
    title: 'Charged Cancellation',
    description: 'Charged Cancellation',
    roleId: ROLE_LIST.OPS.id,
  },
  DO_NOT_INVOICE: {
    id: 1100,
    title: 'Do Not Invoice',
    description: 'Do Not Invoice ',
    roleId: ROLE_LIST.OPS.id,
  },
  AWAITING_TRADESPERSON_ADMIN: {
    id: 1200,
    title: 'Awaiting Tradesperson Admin',
    description: 'Awaiting Tradesperson Admin',
    roleId: ROLE_LIST.OPS.id,
  },
  JOB_REPORT_SENT: {
    id: 1300,
    title: 'Job Report Sent',
    description: 'Job Report Sent',
    roleId: ROLE_LIST.OPS.id,
  },
  INVOICE_SENT: {
    id: 1400,
    title: 'Invoice Sent',
    description: 'Invoice Sent',
    roleId: ROLE_LIST.OPS.id,
  },
  INVOICE_REPORT_SENT: {
    id: 1500,
    title: 'Invoice & Report Sent',
    description: 'Invoice & Report Sent',
    roleId: ROLE_LIST.OPS.id,
  },
};

export const SUPPLIER_JOB_STATUS = {
  SUPPLIER_SCHEDULED: {
    id: 10100,
    ref: 'SUPPLIER_SCHEDULED',
    title: 'Scheduled',
    description: 'Scheduled',
    roleId: ROLE_LIST.SUPPLIER.id,
  },
  SUPPLIER_ISSUE: {
    id: 10200,
    ref: 'SUPPLIER_ISSUE',
    title: 'Issue',
    description: 'Issue',
    roleId: ROLE_LIST.SUPPLIER.id,
  },
  SUPPLIER_REMEDIAL_WORK_NEEDED: {
    id: 10300,
    ref: 'SUPPLIER_REMEDIAL_WORK_NEEDED',
    title: 'Remedial Work Needed',
    description: 'Remedial Work Needed',
    roleId: ROLE_LIST.SUPPLIER.id,
  },
  SUPPLIER_JOB_COMPLETED: {
    id: 10400,
    ref: 'SUPPLIER_JOB_COMPLETED',
    title: 'Completed',
    description: 'Completed',
    roleId: ROLE_LIST.SUPPLIER.id,
  },
};

export const MESSAGE_TYPE = {
  INFO: {
    name: 'Info',
    color: 'blue',
    icon: 'information-outline',
  },
  ERROR: {
    name: 'Error',
    color: 'red',
    icon: 'information-outline',
  },
  WARNING: {
    name: 'Warning',
    color: 'orange',
    icon: 'warning',
  },
  SUCCESS: {
    name: 'Success',
    color: 'green',
    icon: 'checkbox-marked-circle-outline',
  },
};

export interface IRadioOptionType {
  id: number;
  label: string;
  details?: string;
}

export const endTimeDuration = 2;

export const SUBSCRIPTION_LINK = '/subscriptions';

export const NOTI_TYPE = {
  CHAT: 'CHAT',
  JOB: 'JOB',
};

export const INC_TIME_SLOT = 30;
export const HALF_HOUR = 30;
export const WORK_START_TIME = {
  HOURS: 5,
  MINUTES: 30,
};

export interface INotiData {
  type: string;
  title: string;
  jobId: string;
  body: string;
}

export const BACKGROUND_COLOR = {
  [ADMIN_JOB_STATUS.NEW_JOB.id]: '#0c5394',
  [ADMIN_JOB_STATUS.WAITING_CLIENT_CONFIRM.id]: '#6d9eeb',
  [ADMIN_JOB_STATUS.AWAITING_ACCESS.id]: '#9937ff',
  [ADMIN_JOB_STATUS.AWAITING_PARTS.id]: '#b55f06',
  [ADMIN_JOB_STATUS.AWAITING_QUOTATION.id]: '#ff9900',
  [ADMIN_JOB_STATUS.SCHEDULED.id]: '#f1c232',
  [ADMIN_JOB_STATUS.ATTENDED.id]: '#45818e',
  [ADMIN_JOB_STATUS.ISSUE.id]: '#e06666',
  [ADMIN_JOB_STATUS.CANCELLED.id]: '#ea9999',
  [ADMIN_JOB_STATUS.CHARGED_CANCELLATION.id]: '#000000',
  [ADMIN_JOB_STATUS.DO_NOT_INVOICE.id]: '#991200',
  [ADMIN_JOB_STATUS.AWAITING_TRADESPERSON_ADMIN.id]: '#a64d79',
  [ADMIN_JOB_STATUS.JOB_REPORT_SENT.id]: 'gray',
  [ADMIN_JOB_STATUS.INVOICE_SENT.id]: 'lightgray',
  [ADMIN_JOB_STATUS.INVOICE_REPORT_SENT.id]: '#6aa84f',
};

export const COLORS = {
  PRIMARY: '#FB5168',
};

// https://mobily.github.io/stacks/docs/installation/
// https://mobily.github.io/stacks/docs/installation/
// https://medium.com/soluto-engineering/size-matters-5aeeb462900a
// https://github.com/nirsky/react-native-size-matters

// cd $ANDROID_HOME
// tools/bin/sdkmanager --licenses
