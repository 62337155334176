import { AppProps } from 'next/app';
import Head from 'next/head';
import { useEffect, useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  createWebStoragePersistor,
} from 'react-query/createWebStoragePersistor-experimental';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import {
  persistQueryClient,
} from 'react-query/persistQueryClient-experimental';

import { getLayout } from '@admin/layout/Layout';
import theme from '@admin/layout/theme';
import { SnackComponent } from '@components/SnackComponent';
import { CacheProvider, EmotionCache } from '@emotion/react';
import createEmotionCache from '@modules/admin/layout/createEmotionCache';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const queryClientRef = useRef<QueryClient>();

  useEffect(() => {
    if (queryClientRef.current) {
      const localStoragePersistor = createWebStoragePersistor({
        storage: window.localStorage,
      });
      persistQueryClient({
        queryClient: queryClientRef.current,
        persistor: localStoragePersistor,
      });
    }
  }, []);

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
      },
    });
  }

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const getLayoutNew = (Component as any).getLayout || getLayout;
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Qeeper</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <QueryClientProvider client={queryClientRef.current}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Hydrate state={pageProps.dehydratedState}>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            {getLayoutNew(<Component {...pageProps} />)}
            <SnackComponent />
          </ThemeProvider>
        </Hydrate>
      </QueryClientProvider>
    </CacheProvider>
  );
}
