import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetchData } from '../lib/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AcceptJobInput = {
  errorPath: Scalars['String'];
  jobId: Scalars['Float'];
  startTime?: InputMaybe<Scalars['DateTime']>;
  statusId: Scalars['Float'];
  supplierId: Scalars['String'];
};

export type AddChatMessageInput = {
  errorPath: Scalars['String'];
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  jobId: Scalars['Float'];
  jobTitle: Scalars['String'];
  receiverId?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type AddChatMessagePayload = {
  __typename?: 'AddChatMessagePayload';
  chatMessage?: Maybe<ChatMessage>;
  errors?: Maybe<Array<Error>>;
};

export type AddCityInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  currency: Scalars['String'];
  currencySymbol: Scalars['String'];
  errorPath: Scalars['String'];
  servicePriceList?: InputMaybe<Array<IServicePrice>>;
  timezone: Scalars['String'];
};

export type AppHomeSearchJobListInput = {
  cityId?: InputMaybe<Scalars['Float']>;
  clientId?: InputMaybe<Scalars['String']>;
  dateFilter?: InputMaybe<Scalars['String']>;
  dateSort?: InputMaybe<Scalars['String']>;
  isAccepted?: InputMaybe<Scalars['Boolean']>;
  isPreferredSupplier?: InputMaybe<Scalars['Boolean']>;
  isStartTimeNull?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Array<Scalars['Float']>>;
  skillFilter?: InputMaybe<Array<Scalars['Int']>>;
  statusIdList?: InputMaybe<Array<Scalars['Int']>>;
  supplierId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type ChangePasswordInput = {
  errorPath: Scalars['String'];
  newPassword: Scalars['String'];
  password: Scalars['String'];
  profileId: Scalars['String'];
};

export type ChatCheckedInput = {
  isAdmin: Scalars['Boolean'];
  jobId: Scalars['Int'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  job?: Maybe<Job>;
  jobId: Scalars['Float'];
  receiver?: Maybe<Profile>;
  receiverId?: Maybe<Scalars['ID']>;
  sender?: Maybe<Profile>;
  senderId?: Maybe<Scalars['ID']>;
  text: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type City = {
  __typename?: 'City';
  city: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  currencySymbol?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  jobs?: Maybe<Array<Job>>;
  timezone?: Maybe<Scalars['String']>;
};

export type CityDetailsInput = {
  cityId?: InputMaybe<Scalars['Float']>;
};

export type CityPayload = {
  __typename?: 'CityPayload';
  city?: Maybe<City>;
  errors?: Maybe<Array<Error>>;
};

export type CityServicePrice = {
  __typename?: 'CityServicePrice';
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['Int']>;
  servicePrice?: Maybe<Scalars['Float']>;
  serviceType?: Maybe<ServiceType>;
  serviceTypeId?: Maybe<Scalars['Int']>;
};

export type ConfirmEmailInput = {
  token: Scalars['String'];
  url: Scalars['String'];
};

export type CreateBookingInput = {
  address?: InputMaybe<Scalars['String']>;
  adminJobReportNotes?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['Float']>;
  clientCost?: InputMaybe<Scalars['Float']>;
  clientId?: InputMaybe<Scalars['String']>;
  completionDocuments?: InputMaybe<Array<Scalars['Upload']>>;
  completionImages?: InputMaybe<Array<Scalars['Upload']>>;
  completionNotes?: InputMaybe<Scalars['String']>;
  completionVideos?: InputMaybe<Array<Scalars['Upload']>>;
  deletedDocuments?: InputMaybe<Array<Scalars['String']>>;
  deletedImages?: InputMaybe<Array<Scalars['String']>>;
  deletedVideos?: InputMaybe<Array<Scalars['String']>>;
  details?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  errorPath: Scalars['String'];
  fullName: Scalars['String'];
  id?: InputMaybe<Scalars['Float']>;
  images?: InputMaybe<Array<Scalars['Upload']>>;
  internalNotes?: InputMaybe<Scalars['String']>;
  invoiceRef?: InputMaybe<Scalars['String']>;
  labourCost?: InputMaybe<Scalars['Float']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  materialCost?: InputMaybe<Scalars['Float']>;
  otherCost?: InputMaybe<Scalars['Float']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
  preferredSupplierList?: InputMaybe<Array<Scalars['String']>>;
  propertyAccess?: InputMaybe<Scalars['String']>;
  quotationPrice?: InputMaybe<Scalars['Float']>;
  referenceId?: InputMaybe<Scalars['String']>;
  remedialNotes?: InputMaybe<Scalars['String']>;
  roles: Array<Scalars['String']>;
  skillList?: InputMaybe<Array<Scalars['Int']>>;
  slotDuration?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  startTimeSlots?: InputMaybe<Array<IJobTimeSlot>>;
  statusId?: InputMaybe<Scalars['Float']>;
  supplierCost?: InputMaybe<Scalars['Float']>;
  supplierId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  videos?: InputMaybe<Array<Scalars['Upload']>>;
};

export type CreateFeedbackInput = {
  errorPath: Scalars['String'];
  message: Scalars['String'];
};

export type CreateFeedbackPayload = {
  __typename?: 'CreateFeedbackPayload';
  errors?: Maybe<Array<Error>>;
};

export type CreateProfileInput = {
  address?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['Float']>;
  documents?: InputMaybe<Array<Scalars['Upload']>>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmationLink?: InputMaybe<Scalars['String']>;
  errorPath: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  isB2B?: InputMaybe<Scalars['Int']>;
  password?: InputMaybe<Scalars['String']>;
  paymentTerms?: InputMaybe<Scalars['Float']>;
  permissions?: InputMaybe<Array<Scalars['String']>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  servicePriceList?: InputMaybe<Array<IServicePrice>>;
  skillList?: InputMaybe<Array<Scalars['Int']>>;
};

export type CreateServiceTypeInput = {
  errorPath: Scalars['String'];
  isFixed: Scalars['Boolean'];
  isHourly: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CursorPayload = {
  __typename?: 'CursorPayload';
  date: Scalars['DateTime'];
  id: Scalars['String'];
  score: Scalars['Float'];
};

export type Error = {
  __typename?: 'Error';
  message: Array<Scalars['String']>;
  path: Scalars['String'];
};

export type ErrorPayload = {
  __typename?: 'ErrorPayload';
  errors?: Maybe<Array<Error>>;
};

export type Feedback = {
  __typename?: 'Feedback';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  message: Scalars['String'];
  sender?: Maybe<Profile>;
  senderId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FeedbackListPayload = {
  __typename?: 'FeedbackListPayload';
  feedbackList?: Maybe<Array<Feedback>>;
};

export type FindJobItemInput = {
  jobId?: InputMaybe<Scalars['Float']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type ForgotPasswordConfirmEmailInput = {
  emailConfirmationLink: Scalars['String'];
  token: Scalars['String'];
};

export type ForgotPasswordConfirmEmailPayload = {
  __typename?: 'ForgotPasswordConfirmEmailPayload';
  errors?: Maybe<Array<Error>>;
  profileId?: Maybe<Scalars['String']>;
};

export type ForgotPasswordSendEmailInput = {
  email: Scalars['String'];
  emailConfirmationLink: Scalars['String'];
};

export type History = {
  __typename?: 'History';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  newValue?: Maybe<Scalars['JSON']>;
  oldValue?: Maybe<Scalars['JSON']>;
  operation: Scalars['String'];
  primaryId: Scalars['String'];
  tableName?: Maybe<Scalars['String']>;
};

export type HistoryResultObject = {
  __typename?: 'HistoryResultObject';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Float'];
  newValue?: Maybe<Scalars['JSON']>;
  oldValue?: Maybe<Scalars['JSON']>;
  operation: Scalars['String'];
  primaryId: Scalars['String'];
  result: Scalars['String'];
  tableName?: Maybe<Scalars['String']>;
};

export type IJobTimeSlot = {
  date: Scalars['String'];
  slots: Array<Scalars['Int']>;
};

export type IServicePrice = {
  id: Scalars['Int'];
  price: Scalars['Float'];
};

export type IServiceType = {
  id: Scalars['Float'];
  isFixed: Scalars['Boolean'];
  isHourly: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Job = {
  __typename?: 'Job';
  address?: Maybe<Scalars['String']>;
  adminJobReportNotes?: Maybe<Scalars['String']>;
  chatCheckedAdmin?: Maybe<Scalars['DateTime']>;
  chatCheckedSupplier?: Maybe<Scalars['DateTime']>;
  chatMessages?: Maybe<Array<ChatMessage>>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['Float']>;
  clientCost?: Maybe<Scalars['Float']>;
  completionDocuments?: Maybe<Array<Scalars['String']>>;
  completionImages?: Maybe<Array<Scalars['String']>>;
  completionNotes?: Maybe<Scalars['String']>;
  completionVideos?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  images?: Maybe<Array<Scalars['String']>>;
  internalNotes?: Maybe<Scalars['String']>;
  invoiceRef?: Maybe<Scalars['String']>;
  jobStatusList?: Maybe<Array<JobStatus>>;
  labourCost?: Maybe<Scalars['Float']>;
  location?: Maybe<PointObject>;
  materialCost?: Maybe<Scalars['Float']>;
  otherCost?: Maybe<Scalars['Float']>;
  postCode?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['String']>;
  profileList?: Maybe<Array<Profile>>;
  propertyAccess?: Maybe<Scalars['String']>;
  referenceId?: Maybe<Scalars['String']>;
  remedialNotes?: Maybe<Scalars['String']>;
  skillList?: Maybe<Array<Skill>>;
  slotDuration?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['DateTime']>;
  startTimeSlots?: Maybe<Array<JobTimeSlot>>;
  supplierCost?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  videos?: Maybe<Array<Scalars['String']>>;
};

export type JobChatListInput = {
  supplierId?: InputMaybe<Scalars['String']>;
};

export type JobChatListPayload = {
  __typename?: 'JobChatListPayload';
  chatList?: Maybe<Array<ChatMessage>>;
  cursor?: Maybe<CursorPayload>;
  hasMore: Scalars['Boolean'];
};

export type JobDetailsInput = {
  jobId?: InputMaybe<Scalars['Float']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type JobPayload = {
  __typename?: 'JobPayload';
  errors?: Maybe<Array<Error>>;
  job?: Maybe<Job>;
};

export type JobStatus = {
  __typename?: 'JobStatus';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  profileId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
};

export type JobStatusListPayload = {
  __typename?: 'JobStatusListPayload';
  jobStatusList?: Maybe<Array<JobStatus>>;
};

export type JobTimeSlot = {
  __typename?: 'JobTimeSlot';
  date: Scalars['String'];
  slots: Array<Scalars['Int']>;
};

export type ListJobPayload = {
  __typename?: 'ListJobPayload';
  jobList?: Maybe<Array<Job>>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptJob: JobPayload;
  addChatMessage: AddChatMessagePayload;
  addCity: CityPayload;
  changePassword?: Maybe<ProfilePayload>;
  chatChecked: Scalars['Boolean'];
  confirmEmail?: Maybe<ErrorPayload>;
  createBooking?: Maybe<JobPayload>;
  createComment?: Maybe<Scalars['String']>;
  createFeedback: CreateFeedbackPayload;
  createJob?: Maybe<JobPayload>;
  createProfile: ProfilePayload;
  createServiceType: ErrorPayload;
  forgotPasswordConfirmEmail?: Maybe<ForgotPasswordConfirmEmailPayload>;
  forgotPasswordSendEmail?: Maybe<ErrorPayload>;
  login: ProfilePayload;
  logout: Scalars['Boolean'];
  register: ProfilePayload;
  resetPassword?: Maybe<ErrorPayload>;
  sendNoti: Scalars['Boolean'];
  updateCity: CityPayload;
  updateJob?: Maybe<JobPayload>;
  updateProfile: ProfilePayload;
  updateServiceType: ErrorPayload;
};


export type MutationAcceptJobArgs = {
  input: AcceptJobInput;
};


export type MutationAddChatMessageArgs = {
  input: AddChatMessageInput;
};


export type MutationAddCityArgs = {
  input: AddCityInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationChatCheckedArgs = {
  input: ChatCheckedInput;
};


export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};


export type MutationCreateBookingArgs = {
  input: CreateBookingInput;
};


export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};


export type MutationCreateJobArgs = {
  input: UpdateJobInput;
};


export type MutationCreateProfileArgs = {
  input: CreateProfileInput;
};


export type MutationCreateServiceTypeArgs = {
  input: CreateServiceTypeInput;
};


export type MutationForgotPasswordConfirmEmailArgs = {
  input: ForgotPasswordConfirmEmailInput;
};


export type MutationForgotPasswordSendEmailArgs = {
  input: ForgotPasswordSendEmailInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationUpdateCityArgs = {
  input: UpdateCityInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateServiceTypeArgs = {
  input: UpdateServiceTypeInput;
};

export type Permission = {
  __typename?: 'Permission';
  category?: Maybe<PermissionCategory>;
  categoryId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  permissionType?: Maybe<PermissionType>;
  permissionTypeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionCategory = {
  __typename?: 'PermissionCategory';
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Permission>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PermissionType = {
  __typename?: 'PermissionType';
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Point Object */
export type PointObject = {
  __typename?: 'PointObject';
  coordinates: Array<Scalars['Float']>;
  type: Scalars['String'];
};

export type Profile = {
  __typename?: 'Profile';
  address?: Maybe<Scalars['String']>;
  blocked?: Maybe<Scalars['Boolean']>;
  city?: Maybe<City>;
  cityId?: Maybe<Scalars['Float']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Scalars['String']>>;
  email?: Maybe<Scalars['String']>;
  forgotPasswordLocked?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<Scalars['String']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
  isB2B?: Maybe<Scalars['Float']>;
  isPreferred?: Maybe<Scalars['Boolean']>;
  notiTokens?: Maybe<Array<Scalars['String']>>;
  paymentTerms?: Maybe<Scalars['Float']>;
  phoneNumber?: Maybe<Scalars['String']>;
  refreshTokenVersion?: Maybe<Scalars['Float']>;
  reported?: Maybe<Scalars['Boolean']>;
  roleId?: Maybe<Scalars['Float']>;
  roles?: Maybe<Array<Role>>;
  skillList?: Maybe<Array<Skill>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ProfileDetailsInput = {
  profileId?: InputMaybe<Scalars['String']>;
};

export type ProfileListInput = {
  roleName: Scalars['String'];
};

export type ProfileListPayload = {
  __typename?: 'ProfileListPayload';
  profileList?: Maybe<Array<Profile>>;
};

export type ProfilePayload = {
  __typename?: 'ProfilePayload';
  accessToken?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  profile?: Maybe<Profile>;
};

export type ProfileServicePrice = {
  __typename?: 'ProfileServicePrice';
  profile?: Maybe<Profile>;
  profileId?: Maybe<Scalars['ID']>;
  servicePrice?: Maybe<Scalars['Float']>;
  serviceType?: Maybe<ServiceType>;
  serviceTypeId?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  adminChatViewJobDetails: JobPayload;
  adminSearchJobList: SearchJobListPayload;
  appHomeSearchJobList: SearchJobListPayload;
  appNewChatJobList: SearchJobListPayload;
  cityDetails?: Maybe<CityPayload>;
  feedbackList: FeedbackListPayload;
  findJobItem: JobPayload;
  jobChatList: JobChatListPayload;
  jobDetails: JobPayload;
  jobReportJobDetails: JobPayload;
  listJob: ListJobPayload;
  listSkill: Array<Skill>;
  me?: Maybe<ProfilePayload>;
  profileDetails?: Maybe<UserPayload>;
  profileList: ProfileListPayload;
  seachJobReport?: Maybe<JobPayload>;
  searchChatMessage: SearchChatMessagePayload;
  searchCityList: SearchCityListPayload;
  searchCityServicePrice: SearchCityServicePricePayload;
  searchHistory: SearchHistoryPayload;
  searchJobList: SearchJobListPayload;
  searchJobStatusList: JobStatusListPayload;
  searchProfileList: SearchProfileListPayload;
  searchProfileServicePrice: SearchProfileServicePricePayload;
  serviceTypeList: ServiceTypePayload;
};


export type QueryAdminChatViewJobDetailsArgs = {
  input: JobDetailsInput;
};


export type QueryAdminSearchJobListArgs = {
  input: SeachJobListInput;
};


export type QueryAppHomeSearchJobListArgs = {
  input: AppHomeSearchJobListInput;
};


export type QueryAppNewChatJobListArgs = {
  input: SeachJobListInput;
};


export type QueryCityDetailsArgs = {
  input: CityDetailsInput;
};


export type QueryFindJobItemArgs = {
  input: FindJobItemInput;
};


export type QueryJobChatListArgs = {
  input: JobChatListInput;
};


export type QueryJobDetailsArgs = {
  input: JobDetailsInput;
};


export type QueryJobReportJobDetailsArgs = {
  input: JobDetailsInput;
};


export type QueryProfileDetailsArgs = {
  input: ProfileDetailsInput;
};


export type QueryProfileListArgs = {
  input: ProfileListInput;
};


export type QuerySeachJobReportArgs = {
  input: SeachJobReportInput;
};


export type QuerySearchChatMessageArgs = {
  input: SearchChatMessageInput;
};


export type QuerySearchCityServicePriceArgs = {
  input: SearchCityServicePriceInput;
};


export type QuerySearchHistoryArgs = {
  input: SearchHistoryInput;
};


export type QuerySearchJobListArgs = {
  input: SeachJobListInput;
};


export type QuerySearchJobStatusListArgs = {
  input: StatusListInput;
};


export type QuerySearchProfileListArgs = {
  input: SearchProfileListInput;
};


export type QuerySearchProfileServicePriceArgs = {
  input: SearchProfileServicePriceInput;
};

export type RegisterInput = {
  email: Scalars['String'];
  fullName: Scalars['String'];
  password: Scalars['String'];
  roles: Array<Scalars['String']>;
};

export type ResetPasswordInput = {
  errorPath: Scalars['String'];
  password: Scalars['String'];
  profileId: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Permission>>;
  profiles?: Maybe<Array<Profile>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SeachJobListInput = {
  cityId?: InputMaybe<Scalars['Float']>;
  clientId?: InputMaybe<Scalars['String']>;
  dateFilter?: InputMaybe<Scalars['String']>;
  dateSort?: InputMaybe<Scalars['String']>;
  isAccepted?: InputMaybe<Scalars['Boolean']>;
  isStartTimeNull?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Array<Scalars['Float']>>;
  skillFilter?: InputMaybe<Array<Scalars['Int']>>;
  statusIdList?: InputMaybe<Array<Scalars['Int']>>;
  supplierId?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type SeachJobReportInput = {
  jobId: Scalars['Float'];
};

export type SearchChatMessageInput = {
  jobId?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type SearchChatMessagePayload = {
  __typename?: 'SearchChatMessagePayload';
  chatMessageList?: Maybe<Array<ChatMessage>>;
  cursor?: Maybe<CursorPayload>;
  hasMore: Scalars['Boolean'];
  jobDetails?: Maybe<Job>;
};

export type SearchCityListInput = {
  text: Scalars['String'];
};

export type SearchCityListPayload = {
  __typename?: 'SearchCityListPayload';
  cityList?: Maybe<Array<City>>;
};

export type SearchCityServicePriceInput = {
  cityId: Scalars['Float'];
};

export type SearchCityServicePricePayload = {
  __typename?: 'SearchCityServicePricePayload';
  cityServicePriceList?: Maybe<Array<CityServicePrice>>;
};

export type SearchHistoryInput = {
  primaryId?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  tableName?: InputMaybe<Scalars['String']>;
};

export type SearchHistoryPayload = {
  __typename?: 'SearchHistoryPayload';
  historyList?: Maybe<Array<HistoryResultObject>>;
};

export type SearchJobListPayload = {
  __typename?: 'SearchJobListPayload';
  cursor?: Maybe<CursorPayload>;
  hasMore: Scalars['Boolean'];
  jobList?: Maybe<Array<Job>>;
};

export type SearchProfileListInput = {
  roles?: InputMaybe<Array<Scalars['Int']>>;
  text?: InputMaybe<Scalars['String']>;
};

export type SearchProfileListPayload = {
  __typename?: 'SearchProfileListPayload';
  profileList?: Maybe<Array<Profile>>;
};

export type SearchProfileServicePriceInput = {
  profileId: Scalars['String'];
};

export type SearchProfileServicePricePayload = {
  __typename?: 'SearchProfileServicePricePayload';
  servicePriceList?: Maybe<Array<ProfileServicePrice>>;
};

export type ServicePrice = {
  __typename?: 'ServicePrice';
  id: Scalars['Int'];
  price: Scalars['Float'];
};

export type ServiceType = {
  __typename?: 'ServiceType';
  createdAt?: Maybe<Scalars['DateTime']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['Float'];
  isFixed?: Maybe<Scalars['Boolean']>;
  isHourly?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ServiceTypePayload = {
  __typename?: 'ServiceTypePayload';
  serviceTypeList?: Maybe<Array<ServiceType>>;
};

export type Skill = {
  __typename?: 'Skill';
  cityList?: Maybe<Array<SkillToCity>>;
  icon?: Maybe<Scalars['String']>;
  iconName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type SkillToCity = {
  __typename?: 'SkillToCity';
  city?: Maybe<Array<City>>;
  cityId: Scalars['Float'];
  price?: Maybe<Scalars['Float']>;
};

export type StatusListInput = {
  roleId?: InputMaybe<Scalars['Float']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newChatMessage: ChatMessage;
  newComment: Scalars['String'];
};


export type SubscriptionNewChatMessageArgs = {
  receiverId?: InputMaybe<Scalars['String']>;
};

export type TopUsersPayload = {
  __typename?: 'TopUsersPayload';
  errors?: Maybe<Array<Error>>;
  profiles?: Maybe<Array<Profile>>;
};

export type UpdateCityInput = {
  city: Scalars['String'];
  cityId: Scalars['Float'];
  country: Scalars['String'];
  currency: Scalars['String'];
  currencySymbol: Scalars['String'];
  errorPath: Scalars['String'];
  servicePriceList?: InputMaybe<Array<IServicePrice>>;
  timezone: Scalars['String'];
};

export type UpdateJobInput = {
  address?: InputMaybe<Scalars['String']>;
  adminJobReportNotes?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['Float']>;
  clientCost?: InputMaybe<Scalars['Float']>;
  clientId?: InputMaybe<Scalars['String']>;
  completionDocuments?: InputMaybe<Array<Scalars['Upload']>>;
  completionImages?: InputMaybe<Array<Scalars['Upload']>>;
  completionNotes?: InputMaybe<Scalars['String']>;
  completionVideos?: InputMaybe<Array<Scalars['Upload']>>;
  deletedDocuments?: InputMaybe<Array<Scalars['String']>>;
  deletedImages?: InputMaybe<Array<Scalars['String']>>;
  deletedVideos?: InputMaybe<Array<Scalars['String']>>;
  details?: InputMaybe<Scalars['String']>;
  errorPath: Scalars['String'];
  id?: InputMaybe<Scalars['Float']>;
  images?: InputMaybe<Array<Scalars['Upload']>>;
  internalNotes?: InputMaybe<Scalars['String']>;
  invoiceRef?: InputMaybe<Scalars['String']>;
  labourCost?: InputMaybe<Scalars['Float']>;
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  materialCost?: InputMaybe<Scalars['Float']>;
  otherCost?: InputMaybe<Scalars['Float']>;
  postCode?: InputMaybe<Scalars['String']>;
  preferredSupplierList?: InputMaybe<Array<Scalars['String']>>;
  propertyAccess?: InputMaybe<Scalars['String']>;
  quotationPrice?: InputMaybe<Scalars['Float']>;
  referenceId?: InputMaybe<Scalars['String']>;
  remedialNotes?: InputMaybe<Scalars['String']>;
  skillList?: InputMaybe<Array<Scalars['Int']>>;
  slotDuration?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  startTimeSlots?: InputMaybe<Array<IJobTimeSlot>>;
  statusId?: InputMaybe<Scalars['Float']>;
  supplierCost?: InputMaybe<Scalars['Float']>;
  supplierId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  videos?: InputMaybe<Array<Scalars['Upload']>>;
};

export type UpdateProfileInput = {
  address?: InputMaybe<Scalars['String']>;
  cityId?: InputMaybe<Scalars['Float']>;
  deleteNotiToken?: InputMaybe<Scalars['String']>;
  deletedDocuments?: InputMaybe<Array<Scalars['String']>>;
  documents?: InputMaybe<Array<Scalars['Upload']>>;
  email?: InputMaybe<Scalars['String']>;
  emailConfirmationLink?: InputMaybe<Scalars['String']>;
  errorPath: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  isB2B?: InputMaybe<Scalars['Int']>;
  notiToken?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  paymentTerms?: InputMaybe<Scalars['Float']>;
  permissions?: InputMaybe<Array<Scalars['String']>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  profileId?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  servicePriceList?: InputMaybe<Array<IServicePrice>>;
  skillList?: InputMaybe<Array<Scalars['Int']>>;
};

export type UpdateServiceTypeInput = {
  errorPath: Scalars['String'];
  serviceTypeList?: InputMaybe<Array<IServiceType>>;
};

export type UserPayload = {
  __typename?: 'UserPayload';
  accessToken?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  profile?: Maybe<Profile>;
};

export type ChatMessageFragments_ChatMessageFragment = { __typename?: 'ChatMessage', id: number, text: string, isAdmin?: boolean | null, jobId: number, createdAt?: any | null };

export type ChatMessageFragments_ChatMessageDetailsFragment = { __typename?: 'ChatMessage', id: number, text: string, isAdmin?: boolean | null, jobId: number, createdAt?: any | null };

export type CityFragments_CityFragment = { __typename?: 'City', id: number, city: string, country?: string | null, timezone?: string | null, currency?: string | null, currencySymbol?: string | null };

export type CityFragments_AdminCityJobListFragment = { __typename?: 'City', id: number, city: string, timezone?: string | null, currency?: string | null, currencySymbol?: string | null };

export type CursorFragments_CursorFragment = { __typename?: 'CursorPayload', id: string, date: any, score: number };

export type ErrorFragments_ErrorFragment = { __typename?: 'Error', path: string, message: Array<string> };

export type FeedbackFragments_FeedbackFragment = { __typename?: 'Feedback', id: number, message: string, senderId?: string | null, createdAt?: any | null, updatedAt?: any | null };

export type JobFragments_JobFragment = { __typename?: 'Job', id: number, referenceId?: string | null, title?: string | null, slug?: string | null, details?: string | null, completionNotes?: string | null, remedialNotes?: string | null, adminJobReportNotes?: string | null, address?: string | null, postCode?: string | null, propertyAccess?: string | null, slotDuration?: number | null, startTime?: any | null, images?: Array<string> | null, completionImages?: Array<string> | null, videos?: Array<string> | null, completionVideos?: Array<string> | null, completionDocuments?: Array<string> | null, cityId?: number | null, internalNotes?: string | null, labourCost?: number | null, materialCost?: number | null, otherCost?: number | null, clientCost?: number | null, supplierCost?: number | null, createdAt?: any | null, updatedAt?: any | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, location?: { __typename?: 'PointObject', type: string, coordinates: Array<number> } | null, startTimeSlots?: Array<{ __typename?: 'JobTimeSlot', date: string, slots: Array<number> }> | null, jobStatusList?: Array<{ __typename?: 'JobStatus', id: number, title: string, roleId?: number | null, profileId?: string | null }> | null, profileList?: Array<{ __typename?: 'Profile', id: string, fullName?: string | null, roleId?: number | null, isPreferred?: boolean | null, isAccepted?: boolean | null }> | null, city?: { __typename?: 'City', id: number, city: string, country?: string | null, timezone?: string | null, currency?: string | null, currencySymbol?: string | null } | null };

export type JobFragments_JobSimpleFragment = { __typename?: 'Job', id: number, referenceId?: string | null, title?: string | null, slug?: string | null, completionNotes?: string | null, details?: string | null, address?: string | null, postCode?: string | null, propertyAccess?: string | null, slotDuration?: number | null, startTime?: any | null, images?: Array<string> | null, completionImages?: Array<string> | null, videos?: Array<string> | null, completionVideos?: Array<string> | null, completionDocuments?: Array<string> | null, cityId?: number | null, labourCost?: number | null, materialCost?: number | null, otherCost?: number | null, remedialNotes?: string | null, clientCost?: number | null, supplierCost?: number | null, createdAt?: any | null, updatedAt?: any | null, location?: { __typename?: 'PointObject', type: string, coordinates: Array<number> } | null };

export type JobFragments_AdminJobListFragment = { __typename?: 'Job', id: number, referenceId?: string | null, title?: string | null, slug?: string | null, details?: string | null, address?: string | null, postCode?: string | null, slotDuration?: number | null, startTime?: any | null, createdAt?: any | null, cityId?: number | null, internalNotes?: string | null, propertyAccess?: string | null, invoiceRef?: string | null, supplierCost?: number | null, clientCost?: number | null, startTimeSlots?: Array<{ __typename?: 'JobTimeSlot', date: string, slots: Array<number> }> | null, jobStatusList?: Array<{ __typename?: 'JobStatus', id: number, title: string, roleId?: number | null }> | null, profileList?: Array<{ __typename?: 'Profile', id: string, fullName?: string | null, roleId?: number | null, isPreferred?: boolean | null, isAccepted?: boolean | null }> | null };

export type JobFragments_ChatListFragment = { __typename?: 'Job', id: number, title?: string | null, address?: string | null, chatCheckedAdmin?: any | null, chatCheckedSupplier?: any | null, profileList?: Array<{ __typename?: 'Profile', id: string, roleId?: number | null, isAccepted?: boolean | null, isPreferred?: boolean | null }> | null };

export type JobFragments_ChatDetailsFragment = { __typename?: 'Job', id: number, title?: string | null, slug?: string | null, chatCheckedAdmin?: any | null, chatCheckedSupplier?: any | null };

export type JobStatusFragments_JobStatusFragment = { __typename?: 'JobStatus', id: number, title: string, description?: string | null, roleId?: number | null };

export type ProfileFragments_ProfileFragment = { __typename?: 'Profile', id: string, email?: string | null, fullName?: string | null, image?: string | null, address?: string | null, phoneNumber?: string | null, refreshTokenVersion?: number | null, forgotPasswordLocked?: boolean | null, paymentTerms?: number | null, documents?: Array<string> | null, confirmed?: boolean | null, reported?: boolean | null, blocked?: boolean | null, createdAt?: string | null, updatedAt?: string | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, city?: { __typename?: 'City', id: number, city: string } | null, roles?: Array<{ __typename?: 'Role', id: number, name?: string | null }> | null };

export type ProfileFragments_ProfileDetailedFragment = { __typename?: 'Profile', id: string, email?: string | null, fullName?: string | null, image?: string | null, address?: string | null, phoneNumber?: string | null, refreshTokenVersion?: number | null, forgotPasswordLocked?: boolean | null, confirmed?: boolean | null, reported?: boolean | null, blocked?: boolean | null, createdAt?: string | null, updatedAt?: string | null, roles?: Array<{ __typename?: 'Role', id: number, name?: string | null }> | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null };

export type SkillFragments_SkillFragment = { __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null };

export type SkillFragments_AdminJobListSkillFragment = { __typename?: 'Skill', id: number, name: string };

export type MeAppQueryVariables = Exact<{ [key: string]: never; }>;


export type MeAppQuery = { __typename?: 'Query', me?: { __typename?: 'ProfilePayload', profile?: { __typename?: 'Profile', id: string, fullName?: string | null, notiTokens?: Array<string> | null, skillList?: Array<{ __typename?: 'Skill', id: number }> | null, roles?: Array<{ __typename?: 'Role', id: number, name?: string | null }> | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export type CreateBookingMutationVariables = Exact<{
  input: CreateBookingInput;
}>;


export type CreateBookingMutation = { __typename?: 'Mutation', createBooking?: { __typename?: 'JobPayload', job?: { __typename?: 'Job', id: number, referenceId?: string | null, title?: string | null, slug?: string | null, details?: string | null, completionNotes?: string | null, remedialNotes?: string | null, adminJobReportNotes?: string | null, address?: string | null, postCode?: string | null, propertyAccess?: string | null, slotDuration?: number | null, startTime?: any | null, images?: Array<string> | null, completionImages?: Array<string> | null, videos?: Array<string> | null, completionVideos?: Array<string> | null, completionDocuments?: Array<string> | null, cityId?: number | null, internalNotes?: string | null, labourCost?: number | null, materialCost?: number | null, otherCost?: number | null, clientCost?: number | null, supplierCost?: number | null, createdAt?: any | null, updatedAt?: any | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, location?: { __typename?: 'PointObject', type: string, coordinates: Array<number> } | null, startTimeSlots?: Array<{ __typename?: 'JobTimeSlot', date: string, slots: Array<number> }> | null, jobStatusList?: Array<{ __typename?: 'JobStatus', id: number, title: string, roleId?: number | null, profileId?: string | null }> | null, profileList?: Array<{ __typename?: 'Profile', id: string, fullName?: string | null, roleId?: number | null, isPreferred?: boolean | null, isAccepted?: boolean | null }> | null, city?: { __typename?: 'City', id: number, city: string, country?: string | null, timezone?: string | null, currency?: string | null, currencySymbol?: string | null } | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export type AddChatMessageMutationVariables = Exact<{
  input: AddChatMessageInput;
}>;


export type AddChatMessageMutation = { __typename?: 'Mutation', addChatMessage: { __typename?: 'AddChatMessagePayload', chatMessage?: { __typename?: 'ChatMessage', id: number, text: string, isAdmin?: boolean | null, jobId: number, createdAt?: any | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type NewChatMessageSubscriptionVariables = Exact<{
  receiverId?: InputMaybe<Scalars['String']>;
}>;


export type NewChatMessageSubscription = { __typename?: 'Subscription', newChatMessage: { __typename?: 'ChatMessage', id: number, text: string, isAdmin?: boolean | null, jobId: number, createdAt?: any | null } };

export type ChatCheckedMutationVariables = Exact<{
  input: ChatCheckedInput;
}>;


export type ChatCheckedMutation = { __typename?: 'Mutation', chatChecked: boolean };

export type JobChatListQueryVariables = Exact<{
  input: JobChatListInput;
}>;


export type JobChatListQuery = { __typename?: 'Query', jobChatList: { __typename?: 'JobChatListPayload', hasMore: boolean, chatList?: Array<{ __typename?: 'ChatMessage', id: number, text: string, isAdmin?: boolean | null, jobId: number, createdAt?: any | null, job?: { __typename?: 'Job', id: number, title?: string | null, address?: string | null, chatCheckedAdmin?: any | null, chatCheckedSupplier?: any | null, profileList?: Array<{ __typename?: 'Profile', id: string, roleId?: number | null, isAccepted?: boolean | null, isPreferred?: boolean | null }> | null } | null }> | null, cursor?: { __typename?: 'CursorPayload', id: string, date: any, score: number } | null } };

export type SearchChatMessageQueryVariables = Exact<{
  input: SearchChatMessageInput;
}>;


export type SearchChatMessageQuery = { __typename?: 'Query', searchChatMessage: { __typename?: 'SearchChatMessagePayload', hasMore: boolean, chatMessageList?: Array<{ __typename?: 'ChatMessage', id: number, text: string, isAdmin?: boolean | null, jobId: number, createdAt?: any | null }> | null, jobDetails?: { __typename?: 'Job', id: number, title?: string | null, slug?: string | null, chatCheckedAdmin?: any | null, chatCheckedSupplier?: any | null } | null, cursor?: { __typename?: 'CursorPayload', id: string, date: any, score: number } | null } };

export type AddCityMutationVariables = Exact<{
  input: AddCityInput;
}>;


export type AddCityMutation = { __typename?: 'Mutation', addCity: { __typename?: 'CityPayload', city?: { __typename?: 'City', id: number, city: string, country?: string | null, timezone?: string | null, currency?: string | null, currencySymbol?: string | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type CityDetailsQueryVariables = Exact<{
  input: CityDetailsInput;
}>;


export type CityDetailsQuery = { __typename?: 'Query', cityDetails?: { __typename?: 'CityPayload', city?: { __typename?: 'City', id: number, city: string, country?: string | null, currency?: string | null, currencySymbol?: string | null, timezone?: string | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export type SearchCityListQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchCityListQuery = { __typename?: 'Query', searchCityList: { __typename?: 'SearchCityListPayload', cityList?: Array<{ __typename?: 'City', id: number, city: string, country?: string | null, currency?: string | null, currencySymbol?: string | null, timezone?: string | null }> | null } };

export type UpdateCityMutationVariables = Exact<{
  input: UpdateCityInput;
}>;


export type UpdateCityMutation = { __typename?: 'Mutation', updateCity: { __typename?: 'CityPayload', city?: { __typename?: 'City', id: number, city: string, country?: string | null, timezone?: string | null, currency?: string | null, currencySymbol?: string | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type CreateFeedbackMutationVariables = Exact<{
  input: CreateFeedbackInput;
}>;


export type CreateFeedbackMutation = { __typename?: 'Mutation', createFeedback: { __typename?: 'CreateFeedbackPayload', errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type FeedbackListQueryVariables = Exact<{ [key: string]: never; }>;


export type FeedbackListQuery = { __typename?: 'Query', feedbackList: { __typename?: 'FeedbackListPayload', feedbackList?: Array<{ __typename?: 'Feedback', id: number, message: string, senderId?: string | null, createdAt?: any | null, updatedAt?: any | null }> | null } };

export type SearchHistoryQueryVariables = Exact<{
  input: SearchHistoryInput;
}>;


export type SearchHistoryQuery = { __typename?: 'Query', searchHistory: { __typename?: 'SearchHistoryPayload', historyList?: Array<{ __typename?: 'HistoryResultObject', id: number, createdAt?: any | null, tableName?: string | null, primaryId: string, operation: string, result: string }> | null } };

export type AcceptJobMutationVariables = Exact<{
  input: AcceptJobInput;
}>;


export type AcceptJobMutation = { __typename?: 'Mutation', acceptJob: { __typename?: 'JobPayload', job?: { __typename?: 'Job', id: number, referenceId?: string | null, title?: string | null, slug?: string | null, details?: string | null, completionNotes?: string | null, remedialNotes?: string | null, adminJobReportNotes?: string | null, address?: string | null, postCode?: string | null, propertyAccess?: string | null, slotDuration?: number | null, startTime?: any | null, images?: Array<string> | null, completionImages?: Array<string> | null, videos?: Array<string> | null, completionVideos?: Array<string> | null, completionDocuments?: Array<string> | null, cityId?: number | null, internalNotes?: string | null, labourCost?: number | null, materialCost?: number | null, otherCost?: number | null, clientCost?: number | null, supplierCost?: number | null, createdAt?: any | null, updatedAt?: any | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, location?: { __typename?: 'PointObject', type: string, coordinates: Array<number> } | null, startTimeSlots?: Array<{ __typename?: 'JobTimeSlot', date: string, slots: Array<number> }> | null, jobStatusList?: Array<{ __typename?: 'JobStatus', id: number, title: string, roleId?: number | null, profileId?: string | null }> | null, profileList?: Array<{ __typename?: 'Profile', id: string, fullName?: string | null, roleId?: number | null, isPreferred?: boolean | null, isAccepted?: boolean | null }> | null, city?: { __typename?: 'City', id: number, city: string, country?: string | null, timezone?: string | null, currency?: string | null, currencySymbol?: string | null } | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type CreateJobMutationVariables = Exact<{
  input: UpdateJobInput;
}>;


export type CreateJobMutation = { __typename?: 'Mutation', createJob?: { __typename?: 'JobPayload', job?: { __typename?: 'Job', id: number, referenceId?: string | null, title?: string | null, slug?: string | null, details?: string | null, completionNotes?: string | null, remedialNotes?: string | null, adminJobReportNotes?: string | null, address?: string | null, postCode?: string | null, propertyAccess?: string | null, slotDuration?: number | null, startTime?: any | null, images?: Array<string> | null, completionImages?: Array<string> | null, videos?: Array<string> | null, completionVideos?: Array<string> | null, completionDocuments?: Array<string> | null, cityId?: number | null, internalNotes?: string | null, labourCost?: number | null, materialCost?: number | null, otherCost?: number | null, clientCost?: number | null, supplierCost?: number | null, createdAt?: any | null, updatedAt?: any | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, location?: { __typename?: 'PointObject', type: string, coordinates: Array<number> } | null, startTimeSlots?: Array<{ __typename?: 'JobTimeSlot', date: string, slots: Array<number> }> | null, jobStatusList?: Array<{ __typename?: 'JobStatus', id: number, title: string, roleId?: number | null, profileId?: string | null }> | null, profileList?: Array<{ __typename?: 'Profile', id: string, fullName?: string | null, roleId?: number | null, isPreferred?: boolean | null, isAccepted?: boolean | null }> | null, city?: { __typename?: 'City', id: number, city: string, country?: string | null, timezone?: string | null, currency?: string | null, currencySymbol?: string | null } | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export type AdminChatViewJobDetailsQueryVariables = Exact<{
  input: JobDetailsInput;
}>;


export type AdminChatViewJobDetailsQuery = { __typename?: 'Query', adminChatViewJobDetails: { __typename?: 'JobPayload', job?: { __typename?: 'Job', id: number, title?: string | null, profileList?: Array<{ __typename?: 'Profile', id: string, fullName?: string | null, roleId?: number | null, isPreferred?: boolean | null, isAccepted?: boolean | null }> | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type JobReportJobDetailsQueryVariables = Exact<{
  input: JobDetailsInput;
}>;


export type JobReportJobDetailsQuery = { __typename?: 'Query', jobReportJobDetails: { __typename?: 'JobPayload', job?: { __typename?: 'Job', id: number, referenceId?: string | null, title?: string | null, details?: string | null, address?: string | null, slotDuration?: number | null, startTime?: any | null, adminJobReportNotes?: string | null, completionImages?: Array<string> | null, completionVideos?: Array<string> | null, completionDocuments?: Array<string> | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type JobDetailsQueryVariables = Exact<{
  input: JobDetailsInput;
}>;


export type JobDetailsQuery = { __typename?: 'Query', jobDetails: { __typename?: 'JobPayload', job?: { __typename?: 'Job', id: number, referenceId?: string | null, title?: string | null, slug?: string | null, details?: string | null, completionNotes?: string | null, remedialNotes?: string | null, adminJobReportNotes?: string | null, address?: string | null, postCode?: string | null, propertyAccess?: string | null, slotDuration?: number | null, startTime?: any | null, images?: Array<string> | null, completionImages?: Array<string> | null, videos?: Array<string> | null, completionVideos?: Array<string> | null, completionDocuments?: Array<string> | null, cityId?: number | null, internalNotes?: string | null, labourCost?: number | null, materialCost?: number | null, otherCost?: number | null, clientCost?: number | null, supplierCost?: number | null, createdAt?: any | null, updatedAt?: any | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, location?: { __typename?: 'PointObject', type: string, coordinates: Array<number> } | null, startTimeSlots?: Array<{ __typename?: 'JobTimeSlot', date: string, slots: Array<number> }> | null, jobStatusList?: Array<{ __typename?: 'JobStatus', id: number, title: string, roleId?: number | null, profileId?: string | null }> | null, profileList?: Array<{ __typename?: 'Profile', id: string, fullName?: string | null, roleId?: number | null, isPreferred?: boolean | null, isAccepted?: boolean | null }> | null, city?: { __typename?: 'City', id: number, city: string, country?: string | null, timezone?: string | null, currency?: string | null, currencySymbol?: string | null } | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type ListJobQueryVariables = Exact<{ [key: string]: never; }>;


export type ListJobQuery = { __typename?: 'Query', listJob: { __typename?: 'ListJobPayload', jobList?: Array<{ __typename?: 'Job', id: number, slug?: string | null }> | null } };

export type AdminSearchJobListQueryVariables = Exact<{
  input: SeachJobListInput;
}>;


export type AdminSearchJobListQuery = { __typename?: 'Query', adminSearchJobList: { __typename?: 'SearchJobListPayload', hasMore: boolean, jobList?: Array<{ __typename?: 'Job', id: number, referenceId?: string | null, title?: string | null, slug?: string | null, details?: string | null, address?: string | null, postCode?: string | null, slotDuration?: number | null, startTime?: any | null, createdAt?: any | null, cityId?: number | null, internalNotes?: string | null, propertyAccess?: string | null, invoiceRef?: string | null, supplierCost?: number | null, clientCost?: number | null, city?: { __typename?: 'City', id: number, city: string, timezone?: string | null, currency?: string | null, currencySymbol?: string | null } | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string }> | null, startTimeSlots?: Array<{ __typename?: 'JobTimeSlot', date: string, slots: Array<number> }> | null, jobStatusList?: Array<{ __typename?: 'JobStatus', id: number, title: string, roleId?: number | null }> | null, profileList?: Array<{ __typename?: 'Profile', id: string, fullName?: string | null, roleId?: number | null, isPreferred?: boolean | null, isAccepted?: boolean | null }> | null }> | null, cursor?: { __typename?: 'CursorPayload', id: string, date: any, score: number } | null } };

export type AppHomeSearchJobListQueryVariables = Exact<{
  input: AppHomeSearchJobListInput;
}>;


export type AppHomeSearchJobListQuery = { __typename?: 'Query', appHomeSearchJobList: { __typename?: 'SearchJobListPayload', hasMore: boolean, jobList?: Array<{ __typename?: 'Job', id: number, title?: string | null, address?: string | null, slotDuration?: number | null, startTime?: any | null, postCode?: string | null, location?: { __typename?: 'PointObject', type: string, coordinates: Array<number> } | null, startTimeSlots?: Array<{ __typename?: 'JobTimeSlot', date: string, slots: Array<number> }> | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null }> | null, cursor?: { __typename?: 'CursorPayload', id: string, date: any, score: number } | null } };

export type AppNewChatJobListQueryVariables = Exact<{
  input: SeachJobListInput;
}>;


export type AppNewChatJobListQuery = { __typename?: 'Query', appNewChatJobList: { __typename?: 'SearchJobListPayload', hasMore: boolean, jobList?: Array<{ __typename?: 'Job', id: number, title?: string | null, createdAt?: any | null, address?: string | null }> | null, cursor?: { __typename?: 'CursorPayload', id: string, date: any, score: number } | null } };

export type SearchJobListQueryVariables = Exact<{
  input: SeachJobListInput;
}>;


export type SearchJobListQuery = { __typename?: 'Query', searchJobList: { __typename?: 'SearchJobListPayload', hasMore: boolean, jobList?: Array<{ __typename?: 'Job', id: number, referenceId?: string | null, title?: string | null, slug?: string | null, completionNotes?: string | null, details?: string | null, address?: string | null, postCode?: string | null, propertyAccess?: string | null, slotDuration?: number | null, startTime?: any | null, images?: Array<string> | null, completionImages?: Array<string> | null, videos?: Array<string> | null, completionVideos?: Array<string> | null, completionDocuments?: Array<string> | null, cityId?: number | null, labourCost?: number | null, materialCost?: number | null, otherCost?: number | null, remedialNotes?: string | null, clientCost?: number | null, supplierCost?: number | null, createdAt?: any | null, updatedAt?: any | null, jobStatusList?: Array<{ __typename?: 'JobStatus', id: number, title: string, roleId?: number | null }> | null, profileList?: Array<{ __typename?: 'Profile', id: string, fullName?: string | null, roleId?: number | null, isPreferred?: boolean | null, isAccepted?: boolean | null }> | null, city?: { __typename?: 'City', id: number, city: string, country?: string | null, timezone?: string | null, currency?: string | null, currencySymbol?: string | null } | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, location?: { __typename?: 'PointObject', type: string, coordinates: Array<number> } | null }> | null, cursor?: { __typename?: 'CursorPayload', id: string, date: any, score: number } | null } };

export type SearchJobStatusListQueryVariables = Exact<{
  input: StatusListInput;
}>;


export type SearchJobStatusListQuery = { __typename?: 'Query', searchJobStatusList: { __typename?: 'JobStatusListPayload', jobStatusList?: Array<{ __typename?: 'JobStatus', id: number, title: string, description?: string | null, roleId?: number | null }> | null } };

export type UpdateJobMutationVariables = Exact<{
  input: UpdateJobInput;
}>;


export type UpdateJobMutation = { __typename?: 'Mutation', updateJob?: { __typename?: 'JobPayload', job?: { __typename?: 'Job', id: number, referenceId?: string | null, title?: string | null, slug?: string | null, completionNotes?: string | null, details?: string | null, address?: string | null, postCode?: string | null, propertyAccess?: string | null, slotDuration?: number | null, startTime?: any | null, images?: Array<string> | null, completionImages?: Array<string> | null, videos?: Array<string> | null, completionVideos?: Array<string> | null, completionDocuments?: Array<string> | null, cityId?: number | null, labourCost?: number | null, materialCost?: number | null, otherCost?: number | null, remedialNotes?: string | null, clientCost?: number | null, supplierCost?: number | null, createdAt?: any | null, updatedAt?: any | null, city?: { __typename?: 'City', id: number, city: string, country?: string | null, timezone?: string | null, currency?: string | null, currencySymbol?: string | null } | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, location?: { __typename?: 'PointObject', type: string, coordinates: Array<number> } | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword?: { __typename?: 'ProfilePayload', profile?: { __typename?: 'Profile', id: string, email?: string | null, fullName?: string | null, image?: string | null, address?: string | null, phoneNumber?: string | null, refreshTokenVersion?: number | null, forgotPasswordLocked?: boolean | null, paymentTerms?: number | null, documents?: Array<string> | null, confirmed?: boolean | null, reported?: boolean | null, blocked?: boolean | null, createdAt?: string | null, updatedAt?: string | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, city?: { __typename?: 'City', id: number, city: string } | null, roles?: Array<{ __typename?: 'Role', id: number, name?: string | null }> | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export type ConfirmEmailMutationVariables = Exact<{
  input: ConfirmEmailInput;
}>;


export type ConfirmEmailMutation = { __typename?: 'Mutation', confirmEmail?: { __typename?: 'ErrorPayload', errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export type CreateProfileMutationVariables = Exact<{
  input: CreateProfileInput;
}>;


export type CreateProfileMutation = { __typename?: 'Mutation', createProfile: { __typename?: 'ProfilePayload', accessToken?: string | null, profile?: { __typename?: 'Profile', id: string, email?: string | null, fullName?: string | null, image?: string | null, address?: string | null, phoneNumber?: string | null, refreshTokenVersion?: number | null, forgotPasswordLocked?: boolean | null, paymentTerms?: number | null, documents?: Array<string> | null, confirmed?: boolean | null, reported?: boolean | null, blocked?: boolean | null, createdAt?: string | null, updatedAt?: string | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, city?: { __typename?: 'City', id: number, city: string } | null, roles?: Array<{ __typename?: 'Role', id: number, name?: string | null }> | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type ForgotPasswordConfirmEmailMutationVariables = Exact<{
  input: ForgotPasswordConfirmEmailInput;
}>;


export type ForgotPasswordConfirmEmailMutation = { __typename?: 'Mutation', forgotPasswordConfirmEmail?: { __typename?: 'ForgotPasswordConfirmEmailPayload', profileId?: string | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export type ForgotPasswordSendEmailMutationVariables = Exact<{
  input: ForgotPasswordSendEmailInput;
}>;


export type ForgotPasswordSendEmailMutation = { __typename?: 'Mutation', forgotPasswordSendEmail?: { __typename?: 'ErrorPayload', errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export type ProfileDetailsQueryVariables = Exact<{
  input: ProfileDetailsInput;
}>;


export type ProfileDetailsQuery = { __typename?: 'Query', profileDetails?: { __typename?: 'UserPayload', profile?: { __typename?: 'Profile', id: string, email?: string | null, fullName?: string | null, image?: string | null, address?: string | null, phoneNumber?: string | null, refreshTokenVersion?: number | null, forgotPasswordLocked?: boolean | null, paymentTerms?: number | null, documents?: Array<string> | null, confirmed?: boolean | null, reported?: boolean | null, blocked?: boolean | null, createdAt?: string | null, updatedAt?: string | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, city?: { __typename?: 'City', id: number, city: string } | null, roles?: Array<{ __typename?: 'Role', id: number, name?: string | null }> | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export type ProfileListQueryVariables = Exact<{
  input: ProfileListInput;
}>;


export type ProfileListQuery = { __typename?: 'Query', profileList: { __typename?: 'ProfileListPayload', profileList?: Array<{ __typename?: 'Profile', id: string, fullName?: string | null, city?: { __typename?: 'City', city: string } | null }> | null } };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename?: 'ErrorPayload', errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export type SearchProfileListQueryVariables = Exact<{
  input: SearchProfileListInput;
}>;


export type SearchProfileListQuery = { __typename?: 'Query', searchProfileList: { __typename?: 'SearchProfileListPayload', profileList?: Array<{ __typename?: 'Profile', id: string, email?: string | null, fullName?: string | null, image?: string | null, address?: string | null, phoneNumber?: string | null, refreshTokenVersion?: number | null, forgotPasswordLocked?: boolean | null, paymentTerms?: number | null, documents?: Array<string> | null, confirmed?: boolean | null, reported?: boolean | null, blocked?: boolean | null, createdAt?: string | null, updatedAt?: string | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, city?: { __typename?: 'City', id: number, city: string } | null, roles?: Array<{ __typename?: 'Role', id: number, name?: string | null }> | null }> | null } };

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'ProfilePayload', accessToken?: string | null, profile?: { __typename?: 'Profile', id: string, email?: string | null, fullName?: string | null, image?: string | null, address?: string | null, phoneNumber?: string | null, refreshTokenVersion?: number | null, forgotPasswordLocked?: boolean | null, paymentTerms?: number | null, documents?: Array<string> | null, confirmed?: boolean | null, reported?: boolean | null, blocked?: boolean | null, createdAt?: string | null, updatedAt?: string | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, city?: { __typename?: 'City', id: number, city: string } | null, roles?: Array<{ __typename?: 'Role', id: number, name?: string | null }> | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type SeachJobReportQueryVariables = Exact<{
  input: SeachJobReportInput;
}>;


export type SeachJobReportQuery = { __typename?: 'Query', seachJobReport?: { __typename?: 'JobPayload', job?: { __typename?: 'Job', remedialNotes?: string | null, adminJobReportNotes?: string | null, completionImages?: Array<string> | null, completionVideos?: Array<string> | null } | null } | null };

export type CreateServiceTypeMutationVariables = Exact<{
  input: CreateServiceTypeInput;
}>;


export type CreateServiceTypeMutation = { __typename?: 'Mutation', createServiceType: { __typename?: 'ErrorPayload', errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type SearchCityServicePriceQueryVariables = Exact<{
  input: SearchCityServicePriceInput;
}>;


export type SearchCityServicePriceQuery = { __typename?: 'Query', searchCityServicePrice: { __typename?: 'SearchCityServicePricePayload', cityServicePriceList?: Array<{ __typename?: 'CityServicePrice', servicePrice?: number | null, serviceType?: { __typename?: 'ServiceType', id: number, name?: string | null } | null }> | null } };

export type SearchProfileServicePriceQueryVariables = Exact<{
  input: SearchProfileServicePriceInput;
}>;


export type SearchProfileServicePriceQuery = { __typename?: 'Query', searchProfileServicePrice: { __typename?: 'SearchProfileServicePricePayload', servicePriceList?: Array<{ __typename?: 'ProfileServicePrice', servicePrice?: number | null, serviceType?: { __typename?: 'ServiceType', id: number, name?: string | null } | null }> | null } };

export type ServiceTypeListQueryVariables = Exact<{ [key: string]: never; }>;


export type ServiceTypeListQuery = { __typename?: 'Query', serviceTypeList: { __typename?: 'ServiceTypePayload', serviceTypeList?: Array<{ __typename?: 'ServiceType', id: number, name?: string | null, isHourly?: boolean | null, isFixed?: boolean | null }> | null } };

export type UpdateServiceTypeMutationVariables = Exact<{
  input: UpdateServiceTypeInput;
}>;


export type UpdateServiceTypeMutation = { __typename?: 'Mutation', updateServiceType: { __typename?: 'ErrorPayload', errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type ListSkillQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSkillQuery = { __typename?: 'Query', listSkill: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'ProfilePayload', accessToken?: string | null, profile?: { __typename?: 'Profile', id: string, email?: string | null, fullName?: string | null, image?: string | null, address?: string | null, phoneNumber?: string | null, refreshTokenVersion?: number | null, forgotPasswordLocked?: boolean | null, paymentTerms?: number | null, documents?: Array<string> | null, confirmed?: boolean | null, reported?: boolean | null, blocked?: boolean | null, createdAt?: string | null, updatedAt?: string | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null, city?: { __typename?: 'City', id: number, city: string } | null, roles?: Array<{ __typename?: 'Role', id: number, name?: string | null }> | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'ProfilePayload', accessToken?: string | null, profile?: { __typename?: 'Profile', id: string, email?: string | null, fullName?: string | null, image?: string | null, address?: string | null, phoneNumber?: string | null, refreshTokenVersion?: number | null, forgotPasswordLocked?: boolean | null, confirmed?: boolean | null, reported?: boolean | null, blocked?: boolean | null, createdAt?: string | null, updatedAt?: string | null, roles?: Array<{ __typename?: 'Role', id: number, name?: string | null }> | null, skillList?: Array<{ __typename?: 'Skill', id: number, name: string, icon?: string | null, iconName?: string | null }> | null } | null, errors?: Array<{ __typename?: 'Error', path: string, message: Array<string> }> | null } | null };

export const ChatMessageFragments_ChatMessageFragmentDoc = `
    fragment ChatMessageFragments_chatMessage on ChatMessage {
  id
  text
  isAdmin
  jobId
  createdAt
}
    `;
export const ChatMessageFragments_ChatMessageDetailsFragmentDoc = `
    fragment ChatMessageFragments_chatMessageDetails on ChatMessage {
  id
  text
  isAdmin
  jobId
  createdAt
}
    `;
export const CityFragments_AdminCityJobListFragmentDoc = `
    fragment CityFragments_adminCityJobList on City {
  id
  city
  timezone
  currency
  currencySymbol
}
    `;
export const CursorFragments_CursorFragmentDoc = `
    fragment CursorFragments_cursor on CursorPayload {
  id
  date
  score
}
    `;
export const ErrorFragments_ErrorFragmentDoc = `
    fragment ErrorFragments_error on Error {
  path
  message
}
    `;
export const FeedbackFragments_FeedbackFragmentDoc = `
    fragment FeedbackFragments_feedback on Feedback {
  id
  message
  senderId
  createdAt
  updatedAt
}
    `;
export const SkillFragments_SkillFragmentDoc = `
    fragment SkillFragments_skill on Skill {
  id
  name
  icon
  iconName
}
    `;
export const CityFragments_CityFragmentDoc = `
    fragment CityFragments_city on City {
  id
  city
  country
  timezone
  currency
  currencySymbol
}
    `;
export const JobFragments_JobFragmentDoc = `
    fragment JobFragments_job on Job {
  id
  referenceId
  title
  slug
  details
  completionNotes
  remedialNotes
  adminJobReportNotes
  skillList {
    ...SkillFragments_skill
  }
  address
  location {
    type
    coordinates
  }
  postCode
  propertyAccess
  slotDuration
  startTimeSlots {
    date
    slots
  }
  startTime
  images
  completionImages
  videos
  completionVideos
  completionDocuments
  jobStatusList {
    id
    title
    roleId
    profileId
  }
  profileList {
    id
    fullName
    roleId
    isPreferred
    isAccepted
  }
  cityId
  city {
    ...CityFragments_city
  }
  internalNotes
  labourCost
  materialCost
  otherCost
  clientCost
  supplierCost
  createdAt
  updatedAt
}
    ${SkillFragments_SkillFragmentDoc}
${CityFragments_CityFragmentDoc}`;
export const JobFragments_JobSimpleFragmentDoc = `
    fragment JobFragments_jobSimple on Job {
  id
  referenceId
  title
  slug
  completionNotes
  details
  address
  location {
    type
    coordinates
  }
  postCode
  propertyAccess
  slotDuration
  startTime
  images
  completionImages
  videos
  completionVideos
  completionDocuments
  cityId
  labourCost
  materialCost
  otherCost
  remedialNotes
  clientCost
  supplierCost
  createdAt
  updatedAt
}
    `;
export const JobFragments_AdminJobListFragmentDoc = `
    fragment JobFragments_adminJobList on Job {
  id
  referenceId
  title
  slug
  details
  address
  postCode
  slotDuration
  startTimeSlots {
    date
    slots
  }
  jobStatusList {
    id
    title
    roleId
  }
  profileList {
    id
    fullName
    roleId
    isPreferred
    isAccepted
  }
  startTime
  createdAt
  cityId
  internalNotes
  propertyAccess
  invoiceRef
  supplierCost
  clientCost
}
    `;
export const JobFragments_ChatListFragmentDoc = `
    fragment JobFragments_chatList on Job {
  id
  title
  address
  chatCheckedAdmin
  chatCheckedSupplier
  profileList {
    id
    roleId
    isAccepted
    isPreferred
  }
}
    `;
export const JobFragments_ChatDetailsFragmentDoc = `
    fragment JobFragments_chatDetails on Job {
  id
  title
  slug
  chatCheckedAdmin
  chatCheckedSupplier
}
    `;
export const JobStatusFragments_JobStatusFragmentDoc = `
    fragment JobStatusFragments_jobStatus on JobStatus {
  id
  title
  description
  roleId
}
    `;
export const ProfileFragments_ProfileFragmentDoc = `
    fragment ProfileFragments_profile on Profile {
  id
  email
  fullName
  image
  address
  phoneNumber
  refreshTokenVersion
  forgotPasswordLocked
  paymentTerms
  documents
  confirmed
  reported
  blocked
  createdAt
  updatedAt
  skillList {
    ...SkillFragments_skill
  }
  city {
    id
    city
  }
  roles {
    id
    name
  }
}
    ${SkillFragments_SkillFragmentDoc}`;
export const ProfileFragments_ProfileDetailedFragmentDoc = `
    fragment ProfileFragments_profileDetailed on Profile {
  id
  email
  fullName
  image
  address
  phoneNumber
  refreshTokenVersion
  forgotPasswordLocked
  confirmed
  reported
  blocked
  createdAt
  updatedAt
  roles {
    id
    name
  }
  skillList {
    ...SkillFragments_skill
  }
}
    ${SkillFragments_SkillFragmentDoc}`;
export const SkillFragments_AdminJobListSkillFragmentDoc = `
    fragment SkillFragments_adminJobListSkill on Skill {
  id
  name
}
    `;
export const MeAppDocument = `
    query MeApp {
  me {
    profile {
      id
      fullName
      notiTokens
      skillList {
        id
      }
      roles {
        id
        name
      }
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ErrorFragments_ErrorFragmentDoc}`;
export const useMeAppQuery = <
      TData = MeAppQuery,
      TError = unknown
    >(
      variables?: MeAppQueryVariables,
      options?: UseQueryOptions<MeAppQuery, TError, TData>
    ) =>
    useQuery<MeAppQuery, TError, TData>(
      variables === undefined ? ['MeApp'] : ['MeApp', variables],
      fetchData<MeAppQuery, MeAppQueryVariables>(MeAppDocument, variables),
      options
    );
export const CreateBookingDocument = `
    mutation CreateBooking($input: CreateBookingInput!) {
  createBooking(input: $input) {
    job {
      ...JobFragments_job
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${JobFragments_JobFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useCreateBookingMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBookingMutation, TError, CreateBookingMutationVariables, TContext>) =>
    useMutation<CreateBookingMutation, TError, CreateBookingMutationVariables, TContext>(
      ['CreateBooking'],
      (variables?: CreateBookingMutationVariables) => fetchData<CreateBookingMutation, CreateBookingMutationVariables>(CreateBookingDocument, variables)(),
      options
    );
export const AddChatMessageDocument = `
    mutation AddChatMessage($input: AddChatMessageInput!) {
  addChatMessage(input: $input) {
    chatMessage {
      ...ChatMessageFragments_chatMessage
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ChatMessageFragments_ChatMessageFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useAddChatMessageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddChatMessageMutation, TError, AddChatMessageMutationVariables, TContext>) =>
    useMutation<AddChatMessageMutation, TError, AddChatMessageMutationVariables, TContext>(
      ['AddChatMessage'],
      (variables?: AddChatMessageMutationVariables) => fetchData<AddChatMessageMutation, AddChatMessageMutationVariables>(AddChatMessageDocument, variables)(),
      options
    );
export const NewChatMessageDocument = `
    subscription NewChatMessage($receiverId: String) {
  newChatMessage(receiverId: $receiverId) {
    ...ChatMessageFragments_chatMessage
  }
}
    ${ChatMessageFragments_ChatMessageFragmentDoc}`;
export const ChatCheckedDocument = `
    mutation ChatChecked($input: ChatCheckedInput!) {
  chatChecked(input: $input)
}
    `;
export const useChatCheckedMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChatCheckedMutation, TError, ChatCheckedMutationVariables, TContext>) =>
    useMutation<ChatCheckedMutation, TError, ChatCheckedMutationVariables, TContext>(
      ['ChatChecked'],
      (variables?: ChatCheckedMutationVariables) => fetchData<ChatCheckedMutation, ChatCheckedMutationVariables>(ChatCheckedDocument, variables)(),
      options
    );
export const JobChatListDocument = `
    query JobChatList($input: JobChatListInput!) {
  jobChatList(input: $input) {
    chatList {
      ...ChatMessageFragments_chatMessage
      job {
        ...JobFragments_chatList
      }
    }
    hasMore
    cursor {
      ...CursorFragments_cursor
    }
  }
}
    ${ChatMessageFragments_ChatMessageFragmentDoc}
${JobFragments_ChatListFragmentDoc}
${CursorFragments_CursorFragmentDoc}`;
export const useJobChatListQuery = <
      TData = JobChatListQuery,
      TError = unknown
    >(
      variables: JobChatListQueryVariables,
      options?: UseQueryOptions<JobChatListQuery, TError, TData>
    ) =>
    useQuery<JobChatListQuery, TError, TData>(
      ['JobChatList', variables],
      fetchData<JobChatListQuery, JobChatListQueryVariables>(JobChatListDocument, variables),
      options
    );
export const SearchChatMessageDocument = `
    query SearchChatMessage($input: SearchChatMessageInput!) {
  searchChatMessage(input: $input) {
    chatMessageList {
      ...ChatMessageFragments_chatMessageDetails
    }
    jobDetails {
      ...JobFragments_chatDetails
    }
    hasMore
    cursor {
      ...CursorFragments_cursor
    }
  }
}
    ${ChatMessageFragments_ChatMessageDetailsFragmentDoc}
${JobFragments_ChatDetailsFragmentDoc}
${CursorFragments_CursorFragmentDoc}`;
export const useSearchChatMessageQuery = <
      TData = SearchChatMessageQuery,
      TError = unknown
    >(
      variables: SearchChatMessageQueryVariables,
      options?: UseQueryOptions<SearchChatMessageQuery, TError, TData>
    ) =>
    useQuery<SearchChatMessageQuery, TError, TData>(
      ['SearchChatMessage', variables],
      fetchData<SearchChatMessageQuery, SearchChatMessageQueryVariables>(SearchChatMessageDocument, variables),
      options
    );
export const AddCityDocument = `
    mutation AddCity($input: AddCityInput!) {
  addCity(input: $input) {
    city {
      ...CityFragments_city
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${CityFragments_CityFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useAddCityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AddCityMutation, TError, AddCityMutationVariables, TContext>) =>
    useMutation<AddCityMutation, TError, AddCityMutationVariables, TContext>(
      ['AddCity'],
      (variables?: AddCityMutationVariables) => fetchData<AddCityMutation, AddCityMutationVariables>(AddCityDocument, variables)(),
      options
    );
export const CityDetailsDocument = `
    query CityDetails($input: CityDetailsInput!) {
  cityDetails(input: $input) {
    city {
      id
      city
      country
      currency
      currencySymbol
      timezone
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ErrorFragments_ErrorFragmentDoc}`;
export const useCityDetailsQuery = <
      TData = CityDetailsQuery,
      TError = unknown
    >(
      variables: CityDetailsQueryVariables,
      options?: UseQueryOptions<CityDetailsQuery, TError, TData>
    ) =>
    useQuery<CityDetailsQuery, TError, TData>(
      ['CityDetails', variables],
      fetchData<CityDetailsQuery, CityDetailsQueryVariables>(CityDetailsDocument, variables),
      options
    );
export const SearchCityListDocument = `
    query SearchCityList {
  searchCityList {
    cityList {
      id
      city
      country
      currency
      currencySymbol
      timezone
    }
  }
}
    `;
export const useSearchCityListQuery = <
      TData = SearchCityListQuery,
      TError = unknown
    >(
      variables?: SearchCityListQueryVariables,
      options?: UseQueryOptions<SearchCityListQuery, TError, TData>
    ) =>
    useQuery<SearchCityListQuery, TError, TData>(
      variables === undefined ? ['SearchCityList'] : ['SearchCityList', variables],
      fetchData<SearchCityListQuery, SearchCityListQueryVariables>(SearchCityListDocument, variables),
      options
    );
export const UpdateCityDocument = `
    mutation UpdateCity($input: UpdateCityInput!) {
  updateCity(input: $input) {
    city {
      ...CityFragments_city
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${CityFragments_CityFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useUpdateCityMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCityMutation, TError, UpdateCityMutationVariables, TContext>) =>
    useMutation<UpdateCityMutation, TError, UpdateCityMutationVariables, TContext>(
      ['UpdateCity'],
      (variables?: UpdateCityMutationVariables) => fetchData<UpdateCityMutation, UpdateCityMutationVariables>(UpdateCityDocument, variables)(),
      options
    );
export const CreateFeedbackDocument = `
    mutation CreateFeedback($input: CreateFeedbackInput!) {
  createFeedback(input: $input) {
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ErrorFragments_ErrorFragmentDoc}`;
export const useCreateFeedbackMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateFeedbackMutation, TError, CreateFeedbackMutationVariables, TContext>) =>
    useMutation<CreateFeedbackMutation, TError, CreateFeedbackMutationVariables, TContext>(
      ['CreateFeedback'],
      (variables?: CreateFeedbackMutationVariables) => fetchData<CreateFeedbackMutation, CreateFeedbackMutationVariables>(CreateFeedbackDocument, variables)(),
      options
    );
export const FeedbackListDocument = `
    query FeedbackList {
  feedbackList {
    feedbackList {
      ...FeedbackFragments_feedback
    }
  }
}
    ${FeedbackFragments_FeedbackFragmentDoc}`;
export const useFeedbackListQuery = <
      TData = FeedbackListQuery,
      TError = unknown
    >(
      variables?: FeedbackListQueryVariables,
      options?: UseQueryOptions<FeedbackListQuery, TError, TData>
    ) =>
    useQuery<FeedbackListQuery, TError, TData>(
      variables === undefined ? ['FeedbackList'] : ['FeedbackList', variables],
      fetchData<FeedbackListQuery, FeedbackListQueryVariables>(FeedbackListDocument, variables),
      options
    );
export const SearchHistoryDocument = `
    query SearchHistory($input: SearchHistoryInput!) {
  searchHistory(input: $input) {
    historyList {
      id
      createdAt
      tableName
      primaryId
      operation
      result
    }
  }
}
    `;
export const useSearchHistoryQuery = <
      TData = SearchHistoryQuery,
      TError = unknown
    >(
      variables: SearchHistoryQueryVariables,
      options?: UseQueryOptions<SearchHistoryQuery, TError, TData>
    ) =>
    useQuery<SearchHistoryQuery, TError, TData>(
      ['SearchHistory', variables],
      fetchData<SearchHistoryQuery, SearchHistoryQueryVariables>(SearchHistoryDocument, variables),
      options
    );
export const AcceptJobDocument = `
    mutation AcceptJob($input: AcceptJobInput!) {
  acceptJob(input: $input) {
    job {
      ...JobFragments_job
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${JobFragments_JobFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useAcceptJobMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AcceptJobMutation, TError, AcceptJobMutationVariables, TContext>) =>
    useMutation<AcceptJobMutation, TError, AcceptJobMutationVariables, TContext>(
      ['AcceptJob'],
      (variables?: AcceptJobMutationVariables) => fetchData<AcceptJobMutation, AcceptJobMutationVariables>(AcceptJobDocument, variables)(),
      options
    );
export const CreateJobDocument = `
    mutation CreateJob($input: UpdateJobInput!) {
  createJob(input: $input) {
    job {
      ...JobFragments_job
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${JobFragments_JobFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useCreateJobMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateJobMutation, TError, CreateJobMutationVariables, TContext>) =>
    useMutation<CreateJobMutation, TError, CreateJobMutationVariables, TContext>(
      ['CreateJob'],
      (variables?: CreateJobMutationVariables) => fetchData<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, variables)(),
      options
    );
export const AdminChatViewJobDetailsDocument = `
    query AdminChatViewJobDetails($input: JobDetailsInput!) {
  adminChatViewJobDetails(input: $input) {
    job {
      id
      title
      profileList {
        id
        fullName
        roleId
        isPreferred
        isAccepted
      }
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ErrorFragments_ErrorFragmentDoc}`;
export const useAdminChatViewJobDetailsQuery = <
      TData = AdminChatViewJobDetailsQuery,
      TError = unknown
    >(
      variables: AdminChatViewJobDetailsQueryVariables,
      options?: UseQueryOptions<AdminChatViewJobDetailsQuery, TError, TData>
    ) =>
    useQuery<AdminChatViewJobDetailsQuery, TError, TData>(
      ['AdminChatViewJobDetails', variables],
      fetchData<AdminChatViewJobDetailsQuery, AdminChatViewJobDetailsQueryVariables>(AdminChatViewJobDetailsDocument, variables),
      options
    );
export const JobReportJobDetailsDocument = `
    query JobReportJobDetails($input: JobDetailsInput!) {
  jobReportJobDetails(input: $input) {
    job {
      id
      referenceId
      title
      details
      address
      slotDuration
      startTime
      adminJobReportNotes
      completionImages
      completionVideos
      completionDocuments
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ErrorFragments_ErrorFragmentDoc}`;
export const useJobReportJobDetailsQuery = <
      TData = JobReportJobDetailsQuery,
      TError = unknown
    >(
      variables: JobReportJobDetailsQueryVariables,
      options?: UseQueryOptions<JobReportJobDetailsQuery, TError, TData>
    ) =>
    useQuery<JobReportJobDetailsQuery, TError, TData>(
      ['JobReportJobDetails', variables],
      fetchData<JobReportJobDetailsQuery, JobReportJobDetailsQueryVariables>(JobReportJobDetailsDocument, variables),
      options
    );
export const JobDetailsDocument = `
    query JobDetails($input: JobDetailsInput!) {
  jobDetails(input: $input) {
    job {
      ...JobFragments_job
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${JobFragments_JobFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useJobDetailsQuery = <
      TData = JobDetailsQuery,
      TError = unknown
    >(
      variables: JobDetailsQueryVariables,
      options?: UseQueryOptions<JobDetailsQuery, TError, TData>
    ) =>
    useQuery<JobDetailsQuery, TError, TData>(
      ['JobDetails', variables],
      fetchData<JobDetailsQuery, JobDetailsQueryVariables>(JobDetailsDocument, variables),
      options
    );
export const ListJobDocument = `
    query ListJob {
  listJob {
    jobList {
      id
      slug
    }
  }
}
    `;
export const useListJobQuery = <
      TData = ListJobQuery,
      TError = unknown
    >(
      variables?: ListJobQueryVariables,
      options?: UseQueryOptions<ListJobQuery, TError, TData>
    ) =>
    useQuery<ListJobQuery, TError, TData>(
      variables === undefined ? ['ListJob'] : ['ListJob', variables],
      fetchData<ListJobQuery, ListJobQueryVariables>(ListJobDocument, variables),
      options
    );
export const AdminSearchJobListDocument = `
    query AdminSearchJobList($input: SeachJobListInput!) {
  adminSearchJobList(input: $input) {
    jobList {
      ...JobFragments_adminJobList
      city {
        ...CityFragments_adminCityJobList
      }
      skillList {
        ...SkillFragments_adminJobListSkill
      }
    }
    hasMore
    cursor {
      ...CursorFragments_cursor
    }
  }
}
    ${JobFragments_AdminJobListFragmentDoc}
${CityFragments_AdminCityJobListFragmentDoc}
${SkillFragments_AdminJobListSkillFragmentDoc}
${CursorFragments_CursorFragmentDoc}`;
export const useAdminSearchJobListQuery = <
      TData = AdminSearchJobListQuery,
      TError = unknown
    >(
      variables: AdminSearchJobListQueryVariables,
      options?: UseQueryOptions<AdminSearchJobListQuery, TError, TData>
    ) =>
    useQuery<AdminSearchJobListQuery, TError, TData>(
      ['AdminSearchJobList', variables],
      fetchData<AdminSearchJobListQuery, AdminSearchJobListQueryVariables>(AdminSearchJobListDocument, variables),
      options
    );
export const AppHomeSearchJobListDocument = `
    query AppHomeSearchJobList($input: AppHomeSearchJobListInput!) {
  appHomeSearchJobList(input: $input) {
    jobList {
      id
      title
      location {
        type
        coordinates
      }
      address
      slotDuration
      startTimeSlots {
        date
        slots
      }
      startTime
      postCode
      skillList {
        ...SkillFragments_skill
      }
    }
    hasMore
    cursor {
      ...CursorFragments_cursor
    }
  }
}
    ${SkillFragments_SkillFragmentDoc}
${CursorFragments_CursorFragmentDoc}`;
export const useAppHomeSearchJobListQuery = <
      TData = AppHomeSearchJobListQuery,
      TError = unknown
    >(
      variables: AppHomeSearchJobListQueryVariables,
      options?: UseQueryOptions<AppHomeSearchJobListQuery, TError, TData>
    ) =>
    useQuery<AppHomeSearchJobListQuery, TError, TData>(
      ['AppHomeSearchJobList', variables],
      fetchData<AppHomeSearchJobListQuery, AppHomeSearchJobListQueryVariables>(AppHomeSearchJobListDocument, variables),
      options
    );
export const AppNewChatJobListDocument = `
    query AppNewChatJobList($input: SeachJobListInput!) {
  appNewChatJobList(input: $input) {
    jobList {
      id
      title
      createdAt
      address
    }
    hasMore
    cursor {
      ...CursorFragments_cursor
    }
  }
}
    ${CursorFragments_CursorFragmentDoc}`;
export const useAppNewChatJobListQuery = <
      TData = AppNewChatJobListQuery,
      TError = unknown
    >(
      variables: AppNewChatJobListQueryVariables,
      options?: UseQueryOptions<AppNewChatJobListQuery, TError, TData>
    ) =>
    useQuery<AppNewChatJobListQuery, TError, TData>(
      ['AppNewChatJobList', variables],
      fetchData<AppNewChatJobListQuery, AppNewChatJobListQueryVariables>(AppNewChatJobListDocument, variables),
      options
    );
export const SearchJobListDocument = `
    query SearchJobList($input: SeachJobListInput!) {
  searchJobList(input: $input) {
    jobList {
      ...JobFragments_jobSimple
      jobStatusList {
        id
        title
        roleId
      }
      profileList {
        id
        fullName
        roleId
        isPreferred
        isAccepted
      }
      city {
        ...CityFragments_city
      }
      skillList {
        ...SkillFragments_skill
      }
    }
    hasMore
    cursor {
      ...CursorFragments_cursor
    }
  }
}
    ${JobFragments_JobSimpleFragmentDoc}
${CityFragments_CityFragmentDoc}
${SkillFragments_SkillFragmentDoc}
${CursorFragments_CursorFragmentDoc}`;
export const useSearchJobListQuery = <
      TData = SearchJobListQuery,
      TError = unknown
    >(
      variables: SearchJobListQueryVariables,
      options?: UseQueryOptions<SearchJobListQuery, TError, TData>
    ) =>
    useQuery<SearchJobListQuery, TError, TData>(
      ['SearchJobList', variables],
      fetchData<SearchJobListQuery, SearchJobListQueryVariables>(SearchJobListDocument, variables),
      options
    );
export const SearchJobStatusListDocument = `
    query SearchJobStatusList($input: StatusListInput!) {
  searchJobStatusList(input: $input) {
    jobStatusList {
      ...JobStatusFragments_jobStatus
    }
  }
}
    ${JobStatusFragments_JobStatusFragmentDoc}`;
export const useSearchJobStatusListQuery = <
      TData = SearchJobStatusListQuery,
      TError = unknown
    >(
      variables: SearchJobStatusListQueryVariables,
      options?: UseQueryOptions<SearchJobStatusListQuery, TError, TData>
    ) =>
    useQuery<SearchJobStatusListQuery, TError, TData>(
      ['SearchJobStatusList', variables],
      fetchData<SearchJobStatusListQuery, SearchJobStatusListQueryVariables>(SearchJobStatusListDocument, variables),
      options
    );
export const UpdateJobDocument = `
    mutation UpdateJob($input: UpdateJobInput!) {
  updateJob(input: $input) {
    job {
      ...JobFragments_jobSimple
      city {
        ...CityFragments_city
      }
      skillList {
        ...SkillFragments_skill
      }
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${JobFragments_JobSimpleFragmentDoc}
${CityFragments_CityFragmentDoc}
${SkillFragments_SkillFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useUpdateJobMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateJobMutation, TError, UpdateJobMutationVariables, TContext>) =>
    useMutation<UpdateJobMutation, TError, UpdateJobMutationVariables, TContext>(
      ['UpdateJob'],
      (variables?: UpdateJobMutationVariables) => fetchData<UpdateJobMutation, UpdateJobMutationVariables>(UpdateJobDocument, variables)(),
      options
    );
export const ChangePasswordDocument = `
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(input: $input) {
    profile {
      ...ProfileFragments_profile
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ProfileFragments_ProfileFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useChangePasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ChangePasswordMutation, TError, ChangePasswordMutationVariables, TContext>) =>
    useMutation<ChangePasswordMutation, TError, ChangePasswordMutationVariables, TContext>(
      ['ChangePassword'],
      (variables?: ChangePasswordMutationVariables) => fetchData<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, variables)(),
      options
    );
export const ConfirmEmailDocument = `
    mutation ConfirmEmail($input: ConfirmEmailInput!) {
  confirmEmail(input: $input) {
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ErrorFragments_ErrorFragmentDoc}`;
export const useConfirmEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ConfirmEmailMutation, TError, ConfirmEmailMutationVariables, TContext>) =>
    useMutation<ConfirmEmailMutation, TError, ConfirmEmailMutationVariables, TContext>(
      ['ConfirmEmail'],
      (variables?: ConfirmEmailMutationVariables) => fetchData<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, variables)(),
      options
    );
export const CreateProfileDocument = `
    mutation CreateProfile($input: CreateProfileInput!) {
  createProfile(input: $input) {
    profile {
      ...ProfileFragments_profile
    }
    accessToken
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ProfileFragments_ProfileFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useCreateProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateProfileMutation, TError, CreateProfileMutationVariables, TContext>) =>
    useMutation<CreateProfileMutation, TError, CreateProfileMutationVariables, TContext>(
      ['CreateProfile'],
      (variables?: CreateProfileMutationVariables) => fetchData<CreateProfileMutation, CreateProfileMutationVariables>(CreateProfileDocument, variables)(),
      options
    );
export const ForgotPasswordConfirmEmailDocument = `
    mutation ForgotPasswordConfirmEmail($input: ForgotPasswordConfirmEmailInput!) {
  forgotPasswordConfirmEmail(input: $input) {
    profileId
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ErrorFragments_ErrorFragmentDoc}`;
export const useForgotPasswordConfirmEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ForgotPasswordConfirmEmailMutation, TError, ForgotPasswordConfirmEmailMutationVariables, TContext>) =>
    useMutation<ForgotPasswordConfirmEmailMutation, TError, ForgotPasswordConfirmEmailMutationVariables, TContext>(
      ['ForgotPasswordConfirmEmail'],
      (variables?: ForgotPasswordConfirmEmailMutationVariables) => fetchData<ForgotPasswordConfirmEmailMutation, ForgotPasswordConfirmEmailMutationVariables>(ForgotPasswordConfirmEmailDocument, variables)(),
      options
    );
export const ForgotPasswordSendEmailDocument = `
    mutation ForgotPasswordSendEmail($input: ForgotPasswordSendEmailInput!) {
  forgotPasswordSendEmail(input: $input) {
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ErrorFragments_ErrorFragmentDoc}`;
export const useForgotPasswordSendEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ForgotPasswordSendEmailMutation, TError, ForgotPasswordSendEmailMutationVariables, TContext>) =>
    useMutation<ForgotPasswordSendEmailMutation, TError, ForgotPasswordSendEmailMutationVariables, TContext>(
      ['ForgotPasswordSendEmail'],
      (variables?: ForgotPasswordSendEmailMutationVariables) => fetchData<ForgotPasswordSendEmailMutation, ForgotPasswordSendEmailMutationVariables>(ForgotPasswordSendEmailDocument, variables)(),
      options
    );
export const ProfileDetailsDocument = `
    query ProfileDetails($input: ProfileDetailsInput!) {
  profileDetails(input: $input) {
    profile {
      ...ProfileFragments_profile
    }
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ProfileFragments_ProfileFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useProfileDetailsQuery = <
      TData = ProfileDetailsQuery,
      TError = unknown
    >(
      variables: ProfileDetailsQueryVariables,
      options?: UseQueryOptions<ProfileDetailsQuery, TError, TData>
    ) =>
    useQuery<ProfileDetailsQuery, TError, TData>(
      ['ProfileDetails', variables],
      fetchData<ProfileDetailsQuery, ProfileDetailsQueryVariables>(ProfileDetailsDocument, variables),
      options
    );
export const ProfileListDocument = `
    query ProfileList($input: ProfileListInput!) {
  profileList(input: $input) {
    profileList {
      id
      fullName
      city {
        city
      }
    }
  }
}
    `;
export const useProfileListQuery = <
      TData = ProfileListQuery,
      TError = unknown
    >(
      variables: ProfileListQueryVariables,
      options?: UseQueryOptions<ProfileListQuery, TError, TData>
    ) =>
    useQuery<ProfileListQuery, TError, TData>(
      ['ProfileList', variables],
      fetchData<ProfileListQuery, ProfileListQueryVariables>(ProfileListDocument, variables),
      options
    );
export const ResetPasswordDocument = `
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ErrorFragments_ErrorFragmentDoc}`;
export const useResetPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>) =>
    useMutation<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>(
      ['ResetPassword'],
      (variables?: ResetPasswordMutationVariables) => fetchData<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, variables)(),
      options
    );
export const SearchProfileListDocument = `
    query SearchProfileList($input: SearchProfileListInput!) {
  searchProfileList(input: $input) {
    profileList {
      ...ProfileFragments_profile
    }
  }
}
    ${ProfileFragments_ProfileFragmentDoc}`;
export const useSearchProfileListQuery = <
      TData = SearchProfileListQuery,
      TError = unknown
    >(
      variables: SearchProfileListQueryVariables,
      options?: UseQueryOptions<SearchProfileListQuery, TError, TData>
    ) =>
    useQuery<SearchProfileListQuery, TError, TData>(
      ['SearchProfileList', variables],
      fetchData<SearchProfileListQuery, SearchProfileListQueryVariables>(SearchProfileListDocument, variables),
      options
    );
export const UpdateProfileDocument = `
    mutation UpdateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    profile {
      ...ProfileFragments_profile
    }
    accessToken
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ProfileFragments_ProfileFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useUpdateProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateProfileMutation, TError, UpdateProfileMutationVariables, TContext>) =>
    useMutation<UpdateProfileMutation, TError, UpdateProfileMutationVariables, TContext>(
      ['UpdateProfile'],
      (variables?: UpdateProfileMutationVariables) => fetchData<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, variables)(),
      options
    );
export const SeachJobReportDocument = `
    query SeachJobReport($input: SeachJobReportInput!) {
  seachJobReport(input: $input) {
    job {
      remedialNotes
      adminJobReportNotes
      completionImages
      completionVideos
    }
  }
}
    `;
export const useSeachJobReportQuery = <
      TData = SeachJobReportQuery,
      TError = unknown
    >(
      variables: SeachJobReportQueryVariables,
      options?: UseQueryOptions<SeachJobReportQuery, TError, TData>
    ) =>
    useQuery<SeachJobReportQuery, TError, TData>(
      ['SeachJobReport', variables],
      fetchData<SeachJobReportQuery, SeachJobReportQueryVariables>(SeachJobReportDocument, variables),
      options
    );
export const CreateServiceTypeDocument = `
    mutation CreateServiceType($input: CreateServiceTypeInput!) {
  createServiceType(input: $input) {
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ErrorFragments_ErrorFragmentDoc}`;
export const useCreateServiceTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateServiceTypeMutation, TError, CreateServiceTypeMutationVariables, TContext>) =>
    useMutation<CreateServiceTypeMutation, TError, CreateServiceTypeMutationVariables, TContext>(
      ['CreateServiceType'],
      (variables?: CreateServiceTypeMutationVariables) => fetchData<CreateServiceTypeMutation, CreateServiceTypeMutationVariables>(CreateServiceTypeDocument, variables)(),
      options
    );
export const SearchCityServicePriceDocument = `
    query SearchCityServicePrice($input: SearchCityServicePriceInput!) {
  searchCityServicePrice(input: $input) {
    cityServicePriceList {
      serviceType {
        id
        name
      }
      servicePrice
    }
  }
}
    `;
export const useSearchCityServicePriceQuery = <
      TData = SearchCityServicePriceQuery,
      TError = unknown
    >(
      variables: SearchCityServicePriceQueryVariables,
      options?: UseQueryOptions<SearchCityServicePriceQuery, TError, TData>
    ) =>
    useQuery<SearchCityServicePriceQuery, TError, TData>(
      ['SearchCityServicePrice', variables],
      fetchData<SearchCityServicePriceQuery, SearchCityServicePriceQueryVariables>(SearchCityServicePriceDocument, variables),
      options
    );
export const SearchProfileServicePriceDocument = `
    query SearchProfileServicePrice($input: SearchProfileServicePriceInput!) {
  searchProfileServicePrice(input: $input) {
    servicePriceList {
      serviceType {
        id
        name
      }
      servicePrice
    }
  }
}
    `;
export const useSearchProfileServicePriceQuery = <
      TData = SearchProfileServicePriceQuery,
      TError = unknown
    >(
      variables: SearchProfileServicePriceQueryVariables,
      options?: UseQueryOptions<SearchProfileServicePriceQuery, TError, TData>
    ) =>
    useQuery<SearchProfileServicePriceQuery, TError, TData>(
      ['SearchProfileServicePrice', variables],
      fetchData<SearchProfileServicePriceQuery, SearchProfileServicePriceQueryVariables>(SearchProfileServicePriceDocument, variables),
      options
    );
export const ServiceTypeListDocument = `
    query ServiceTypeList {
  serviceTypeList {
    serviceTypeList {
      id
      name
      isHourly
      isFixed
    }
  }
}
    `;
export const useServiceTypeListQuery = <
      TData = ServiceTypeListQuery,
      TError = unknown
    >(
      variables?: ServiceTypeListQueryVariables,
      options?: UseQueryOptions<ServiceTypeListQuery, TError, TData>
    ) =>
    useQuery<ServiceTypeListQuery, TError, TData>(
      variables === undefined ? ['ServiceTypeList'] : ['ServiceTypeList', variables],
      fetchData<ServiceTypeListQuery, ServiceTypeListQueryVariables>(ServiceTypeListDocument, variables),
      options
    );
export const UpdateServiceTypeDocument = `
    mutation UpdateServiceType($input: UpdateServiceTypeInput!) {
  updateServiceType(input: $input) {
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ErrorFragments_ErrorFragmentDoc}`;
export const useUpdateServiceTypeMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateServiceTypeMutation, TError, UpdateServiceTypeMutationVariables, TContext>) =>
    useMutation<UpdateServiceTypeMutation, TError, UpdateServiceTypeMutationVariables, TContext>(
      ['UpdateServiceType'],
      (variables?: UpdateServiceTypeMutationVariables) => fetchData<UpdateServiceTypeMutation, UpdateServiceTypeMutationVariables>(UpdateServiceTypeDocument, variables)(),
      options
    );
export const ListSkillDocument = `
    query ListSkill {
  listSkill {
    ...SkillFragments_skill
  }
}
    ${SkillFragments_SkillFragmentDoc}`;
export const useListSkillQuery = <
      TData = ListSkillQuery,
      TError = unknown
    >(
      variables?: ListSkillQueryVariables,
      options?: UseQueryOptions<ListSkillQuery, TError, TData>
    ) =>
    useQuery<ListSkillQuery, TError, TData>(
      variables === undefined ? ['ListSkill'] : ['ListSkill', variables],
      fetchData<ListSkillQuery, ListSkillQueryVariables>(ListSkillDocument, variables),
      options
    );
export const LoginDocument = `
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    profile {
      ...ProfileFragments_profile
    }
    accessToken
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ProfileFragments_ProfileFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>) =>
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      ['Login'],
      (variables?: LoginMutationVariables) => fetchData<LoginMutation, LoginMutationVariables>(LoginDocument, variables)(),
      options
    );
export const LogoutDocument = `
    mutation Logout {
  logout
}
    `;
export const useLogoutMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<LogoutMutation, TError, LogoutMutationVariables, TContext>) =>
    useMutation<LogoutMutation, TError, LogoutMutationVariables, TContext>(
      ['Logout'],
      (variables?: LogoutMutationVariables) => fetchData<LogoutMutation, LogoutMutationVariables>(LogoutDocument, variables)(),
      options
    );
export const MeDocument = `
    query Me {
  me {
    profile {
      ...ProfileFragments_profileDetailed
    }
    accessToken
    errors {
      ...ErrorFragments_error
    }
  }
}
    ${ProfileFragments_ProfileDetailedFragmentDoc}
${ErrorFragments_ErrorFragmentDoc}`;
export const useMeQuery = <
      TData = MeQuery,
      TError = unknown
    >(
      variables?: MeQueryVariables,
      options?: UseQueryOptions<MeQuery, TError, TData>
    ) =>
    useQuery<MeQuery, TError, TData>(
      variables === undefined ? ['Me'] : ['Me', variables],
      fetchData<MeQuery, MeQueryVariables>(MeDocument, variables),
      options
    );