import {
  MdChevronLeft,
  MdChevronRight,
  MdHomeRepairService,
  MdLocationCity,
  MdOutlineWork,
  MdPeople,
} from 'react-icons/md';

import { ROLE_LIST } from '@common/constants';
import { useMeQuery } from '@controller/schema';
import { CircularProgress, List, ListSubheader } from '@mui/material';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import { WEB_ADMIN_ROUTE } from '@util/constants';

import { DrawerItems } from './DrawerItems';

interface Props {
  openDrawer: boolean;
  toggleDrawer: () => void;
}
export const DrawerComponent = (props: Props) => {
  const theme = useTheme();
  const { openDrawer, toggleDrawer } = props;
  const { data, isLoading } = useMeQuery({}, { staleTime: 5 * 60 * 1000 });
  let listData: any[] = [];
  if (isLoading) {
    return <CircularProgress />;
  } else if (data && data.me?.profile) {
    const roles = data.me?.profile?.roles;
    roles?.map((role) => {
      if (ROLE_LIST.ADMIN.id === role?.id || ROLE_LIST.OPS.id === role?.id) {
        listData = [...listData, ...OPS];
      } else if (ROLE_LIST.SUPERADMIN.id === role?.id) {
        listData = [...listData, ...OPS, ...SUPER_ADMIN];
      }
    });
    return (
      <>
        <Drawer variant="permanent" open={openDrawer}>
          <DrawerHeader>
            <IconButton onClick={toggleDrawer} color="primary">
              {theme.direction === 'rtl' ? (
                <MdChevronRight />
              ) : (
                <MdChevronLeft />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />

          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Management
              </ListSubheader>
            }>
            {/* <Divider variant="inset" light />
          <DrawerItems
            icon={<MdOutlineBarChart size={28} />}
            title="Finance"
            defaultOpenList={true}
            mainLink={WEB_ROUTE_NAMES.FINANCE}
          /> */}
            {listData?.map((item, index) => {
              const { Icon, title, mainLink, defaultOpenList } = item;
              return (
                <span key={title + index}>
                  <Divider variant="inset" light />
                  <DrawerItems
                    icon={<Icon size={28} />}
                    title={title}
                    defaultOpenList={defaultOpenList}
                    mainLink={mainLink}
                  />
                </span>
              );
            })}
          </List>
          <Divider />
        </Drawer>
      </>
    );
  }
  return null;
};

const OPS = [
  {
    Icon: MdOutlineWork,
    title: 'Job',
    mainLink: WEB_ADMIN_ROUTE.JOB,
    defaultOpenList: true,
  },
  { Icon: MdPeople, title: 'Client', mainLink: WEB_ADMIN_ROUTE.CLIENT },
  { Icon: MdPeople, title: 'Supplier', mainLink: WEB_ADMIN_ROUTE.SUPPLIER },
];

const SUPER_ADMIN = [
  { Icon: MdPeople, title: 'Admin', mainLink: WEB_ADMIN_ROUTE.ADMIN },
  { Icon: MdLocationCity, title: 'City', mainLink: WEB_ADMIN_ROUTE.CITY },
  {
    Icon: MdHomeRepairService,
    title: 'Services',
    mainLink: WEB_ADMIN_ROUTE.SERVICE,
  },
];

export const DRAWER_WIDTH = 180;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});
