import React from 'react';

import { useMeQuery } from '@controller/schema';

import { GuestAppBar } from './GuestAppBar';
import { HeaderView } from './HeaderView';
import { LoggedUserAppBar } from './LoggedUserAppBar';

export const HeaderConnector = () => {
  const { data } = useMeQuery({}, { staleTime: 5 * 60 * 1000 });

  const renderDesktop = () => {
    if (data && data.me?.profile) {
      return <LoggedUserAppBar />;
    } else {
      return <GuestAppBar />;
    }
  };

  return <HeaderView renderDesktop={renderDesktop} />;
};
