import React, { useState } from 'react';
import {
  MdAccountCircle,
  MdLogout,
  MdMessage,
  MdMore,
  MdSettings,
} from 'react-icons/md';

import { Link } from '@components/Link';
import { Button, Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import { WEB_ADMIN_ROUTE } from '@util/constants';

export const LoggedUserAppBar = () => {
  const [accountAnchorEl, setAccountAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  const isAccountMenuOpen = Boolean(accountAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAccountAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAccountAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const accountMenuId = 'account-menu';
  const mobileMenuId = 'mobile-menu';

  const renderProfileMenu = () => (
    <Menu
      anchorEl={accountAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={accountMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isAccountMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem onClick={handleMenuClose}>
        {renderSettingsMenuItem('text')}
      </MenuItem>
      <MenuItem component={Link} href={WEB_ADMIN_ROUTE.LOGOUT}>
        {renderLogoutMenuItem('text')}
      </MenuItem>
    </Menu>
  );

  const renderLogoutMenuItem = (variant: any) => (
    <Button
      variant={variant}
      startIcon={<MdLogout />}
      color="inherit"
      size="large"
      sx={{ textTransform: 'none' }}
      aria-label="account of current user"
      aria-controls="primary-search-account-menu"
      aria-haspopup="true">
      Logout
    </Button>
  );
  const renderSettingsMenuItem = (variant: any) => (
    <Button
      variant={variant}
      startIcon={<MdSettings />}
      color="inherit"
      size="large"
      sx={{ textTransform: 'none' }}
      aria-label="account of current user"
      aria-controls="primary-search-account-menu"
      aria-haspopup="true">
      Settings
    </Button>
  );
  const renderMobileMenu = () => (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem divider autoFocus onClick={handleProfileMenuOpen}>
        {renderAccountMenuItem()}
      </MenuItem>
      <MenuItem divider>{renderChatItem()}</MenuItem>
    </Menu>
  );
  const renderAccountMenuItem = () => (
    <>
      <IconButton
        onClick={handleProfileMenuOpen}
        color="inherit"
        sx={{ backgroundColor: 'lightgrey' }}
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true">
        <MdAccountCircle />
      </IconButton>
    </>
  );
  const renderChatItem = () => (
    <IconButton
      component={Link as any}
      href={WEB_ADMIN_ROUTE.CHAT}
      color="inherit"
      sx={{ backgroundColor: 'lightgrey', mr: 3 }}
      aria-label="chat messages"
      aria-controls="primary-search-account-menu"
      aria-haspopup="true">
      <MdMessage />
    </IconButton>
  );
  return (
    <>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>{renderChatItem()}</Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        {renderAccountMenuItem()}
      </Box>

      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          size="large"
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit">
          <MdMore />
        </IconButton>
      </Box>
      {renderMobileMenu()}
      {renderProfileMenu()}
    </>
  );
};
