import create from 'zustand';

import { AlertColor } from '@mui/material/Alert';

export interface SnackComponentProps {
  message: string;
  severity: AlertColor;
}

export interface SnackStore {
  snackMessage: SnackComponentProps | undefined | null;
  setSnackMessage: (
    snackMessage: SnackComponentProps | undefined | null,
  ) => void;
}

export const useSnackStore = create<SnackStore>((set) => ({
  snackMessage: undefined,
  setSnackMessage: (snackMessage: SnackComponentProps | undefined | null) => {
    set({ snackMessage });
  },
}));
