import React, { useEffect, useState } from 'react';
import { MdChevronRight } from 'react-icons/md';

import { Link } from '@components/Link';
import { useMeQuery } from '@controller/schema';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Box } from '@mui/system';
import { WEB_ADMIN_ROUTE } from '@util/constants';

import { DRAWER_WIDTH, DrawerComponent } from './drawer/DrawerComponent';
import { SearchComponent } from './SearchComponent';

interface Props {
  renderDesktop: () => JSX.Element;
}
export const HeaderView = (props: Props) => {
  const { data } = useMeQuery({}, { staleTime: 5 * 60 * 1000 });
  const loggedUser = data?.me?.profile;
  const [openDrawer, setOpenDrawer] = useState(Boolean(loggedUser));
  const toggleDrawer = () => setOpenDrawer((value) => !value);
  useEffect(() => {
    setOpenDrawer(Boolean(loggedUser));
  }, [loggedUser]);
  const { renderDesktop } = props;
  return (
    <>
      <CssBaseline />
      {/* Drawer will be closed if user is not logged in. Otherwise it will be open */}
      <AppBar position="fixed" open={openDrawer} color="inherit">
        <Toolbar>
          {/* Hide drawer icon if user is not logged in */}
          {loggedUser && (
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(openDrawer && { display: 'none' }),
              }}>
              <MdChevronRight />
            </IconButton>
          )}
          <Link
            variant="h5"
            noWrap
            href={WEB_ADMIN_ROUTE.HOME}
            color="primary"
            underline="none"
            fontWeight="bold"
            sx={{ display: { xs: 'none', sm: 'block' } }}>
            Qeeper
          </Link>
          <SearchComponent />
          <Box sx={{ flexGrow: 1 }} />
          {renderDesktop()}
        </Toolbar>
      </AppBar>
      {/* Hide drawer if user is not logged in */}
      {loggedUser && (
        <DrawerComponent openDrawer={openDrawer} toggleDrawer={toggleDrawer} />
      )}
    </>
  );
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
