import React from 'react';

import { useSnackStore } from '@/store/SnackStore';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export interface SnackComponentProps {
  message?: string;
  severity?: AlertColor;
}

export const SnackComponent = (_: SnackComponentProps) => {
  const snackStore = useSnackStore();

  const handleClose = (
    _?: Event | React.SyntheticEvent<any, Event>,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    snackStore.setSnackMessage(null);
  };

  return (
    <Snackbar
      open={Boolean(snackStore?.snackMessage?.message)}
      autoHideDuration={4000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert
        onClose={handleClose}
        severity={snackStore?.snackMessage?.severity}
        sx={{ width: '100%' }}>
        {snackStore?.snackMessage?.message}
      </Alert>
    </Snackbar>
  );
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
